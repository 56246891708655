port module Main exposing (main)

import About
import Admin.Main as Admin
import Admin.Route
import Auth0 exposing (AuthenticationState(..), decodeAuthenticationState)
import Authentication
import Basics
import Box.BuildPackage exposing (buildPackage)
import Breakpoint exposing (toBreakpoint)
import Browser
import Browser.Dom as Dom
import Browser.Events as Events exposing (onResize)
import Browser.Navigation as Nav
import Button.Button as Button
import Button.ButtonIcon as ButtonIcon
import Button.ButtonRounded as ButtonRounded
import CallToAction.CallToAction as CallToAction exposing (fromString, isCarService, isEventService, isFlightService, isRestaurantService, isStayService, isTeeTimeService)
import Card.Load as CardLoader
import Card.Step exposing (Step, step)
import Card.Tiny exposing (Url(..))
import Cars.Ads as CarAds
import Cars.Car as Car
import Cars.Main as Car
import Cars.View as Car
import Configuration as Config exposing (DisabledProvider)
import CookiesPolicies
import Date exposing (Date)
import Date.Extra as Date
import DatePicker.DatePickerMultiple as DP
import Debounce
import Deplecated.Cards.Element as Cards
import Deplecated.Component as Component exposing (DropdownChoice(..), ExcerptChoice)
import Deplecated.Container as Container
import Deplecated.Content as Content
import Deplecated.DatePicker.DoubleDatePicker as DPD
import Deplecated.Dropdown.Element as Dropdown
import Deplecated.Dropdown.Item as DropdownItem
import Deplecated.Dropdown.Sub as DropdownSub
import Deplecated.List as UIList
import Deplecated.Sidebar as Sidebar
import Deplecated.UI.Content exposing (Sorter(..), content)
import Deplecated.UI.Package exposing (myPackageCard)
import Deplecated.UI.SearchableDropdown as SD
import Destination.Destination as Destination
import Destination.RequestForm
import DestinationsPage
import Dict
import Event.Event as Event
import Event.Main as Event
import Event.Viator exposing (getCategories)
import Event.View as Event
import FAQ
import Flight.Filter.Main as FFilter
import Flight.Flight as Flight
import Flight.Kayak.Ads
import Flight.Main as Flight
import Flight.View as Flight
import Golf.Filter exposing (golfFilters)
import Golf.Golf as Golf
import Golf.Main as Golf
import Golf.View as Golf
import Header.Header
import Home.Home as Home exposing (Msg(..))
import HomePackageCards as HPC
import Hotel.Ads as Ads
import Hotel.Filter.Main as HFilter
import Hotel.Hotel as Hotel
import Hotel.Main as Hotel
import Hotel.View as HotelView
import Html exposing (Html, a, button, div, h1, i, img, input, li, p, section, span, text, ul)
import Html.Attributes as Attrs exposing (class, classList, href, id, src)
import Html.Events exposing (onBlur, onClick, onInput)
import Http exposing (Error(..))
import I18n
import Iso8601 exposing (toTime)
import Json.Decode as Decode
import Json.Decode.Pipeline as Decode
import Json.Encode as Encode exposing (Value)
import Layout.Deplecated.Sidebar exposing (sidebar)
import Layout.Footer exposing (footer_)
import List
import List.Extra as List
import Loader
import Location.Airports
import Location.Cities as Location exposing (City)
import Location.CityWithNearestAirport exposing (CityWithNearestAirport, getCitiesAndNearestAirports)
import MBTNews
import MapModal
import Maybe.Extra as Maybe
import Money
import MyPackage
import MyPackagePreviewModal
import MyPrismic.App exposing (AppDocType)
import MyPrismic.Destination exposing (DestinationDocType, fetchAllDestinations, fetchFeaturesEvents, fetchTopDestinations)
import PackageParameters as PackageP exposing (Parameters, defaultParameters)
import Packages.Package as Packages
import Ports exposing (isSubscribed, onDropdownClick, onForceCloseMobileMenu, toggleFilter)
import Prismic
import Prismic.Field as Field
import Prismic.Internal exposing (Link(..))
import PrivatePolicies as Policies
import Profil
import Promotion.Promotion as Promotion exposing (Promotion)
import Rates
import Rating exposing (rating)
import Refresh
import RemoteData exposing (RemoteData(..))
import RemoteData.Extra
import RequestPackage.RequestPackageFunnel as RequestPackageFunnel
import RequestPackage.Route
import Resort
import Restaurant.Main as Restaurant
import Restaurant.Restaurant as Restaurant
import Restaurant.View as Restaurant
import Restaurant.Zomato as Zomato
import Route
import Section.Regular exposing (regular)
import SignupModal
import Sorter exposing (mobileSorter)
import String.Extra as String
import Task
import TermsAndConditions
import Time exposing (Posix)
import Time.Extra as Time
import Toast exposing (Toast(..))
import Url exposing (Url)
import Url.Builder as Builder
import Viewport.Extra
import WebData exposing (Error(..), WebData)


type Status
    = Close



-- PORTS


port auth0authorize : Auth0.Options -> Cmd msg


port auth0authResult : (Decode.Value -> msg) -> Sub msg


port auth0logout : Maybe String -> Cmd msg


port onViewportLoaded : () -> Cmd msg


port handleRedirectCallback : () -> Cmd msg


port handleUserInformation : (Maybe Profile -> msg) -> Sub msg


port handleGeoLocation : (Maybe ( Float, Float ) -> msg) -> Sub msg


port fetchPackage : () -> Cmd msg


port handlePackage : (Value -> msg) -> Sub msg


port handleScrollOrResize : (ScrollReturns -> msg) -> Sub msg


port savePackageDropdown : ( Value, Basics.Bool ) -> Cmd msg


port toggleCarousel : Bool -> Cmd msg


port onRouteChange : () -> Cmd msg


port saveCurrency : String -> Cmd msg


type alias Model =
    { config : Config.Config
    , refresh : Refresh.Refresh
    , overlayHeight : Float
    , stickyHeader : Bool
    , devError : Maybe Decode.Error
    , username : String
    , currentRoute : Route.Route
    , url : Maybe Url
    , key : Nav.Key
    , zone : Time.Zone
    , today : Date
    , profile : Maybe Profile
    , maybeFrom : Maybe ( Float, Float )
    , from : SD.Msg CityWithNearestAirport
    , httpError : Maybe Http.Error
    , isHomeFormOpen : Bool
    , pageCount : Int
    , loadingMore : Dict.Dict String ( Int, Int, Bool )
    , shouldReload : Bool
    , toast : Toast
    , modalInputError : String
    , applicationOpenedAt : Maybe Time.Posix
    , promoOpen : Bool
    , videoOpen : Bool
    , adminPage : Admin.Model
    , profilPage : Profil.Model
    , promotion : WebData Promotion
    , appPrismic : RemoteData Prismic.PrismicError AppDocType
    , prismic : Prismic.Model
    , topDestinations : RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType )
    , allDestinations : RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType )
    , featureEvents : RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType )

    -- Query
    , query : String

    -- DOM
    , animateLogo : Maybe Dom.Element
    , dropdownOpen : DropdownChoice
    , isEditableTitleActivated : Bool
    , formItemDropdown : List Component.FormItemDropdown
    , modalMode : MapModal.Mode
    , openMenu : Bool
    , openFilter : Bool
    , openCurrency : Bool
    , openDetail : Bool
    , excerptChoices : List ExcerptChoice

    -- Sort
    , isSorterOpen : Bool

    -- Packages
    , packagePage : Int
    , myPackage : Maybe MyPackage.MyPackage
    , loadedPackage : WebData MyPackage.MyPackage
    , packageParameters : Parameters
    , flightSorter : Sorter
    , idToOpenOptions : Maybe String

    -- Package
    -- Packages
    , packages : WebData Packages.CustomPackage

    -- Flights
    , flight : Flight.Model
    , debounceDestinationQuery : Debounce.Debounce String
    , debounceFromQuery : Debounce.Debounce String

    -- CallToAction
    -- HOME
    , home : Home.Model

    -- Hotels
    , hotel : Hotel.Model
    , hotelPage : Int
    , hotelSorter : Sorter
    , displayMap : Bool
    , onlyAirbnb : Bool

    -- Events
    , event : Event.Model
    , eventPage : Int
    , eventSorter : Sorter

    -- Restaurants
    , restaurantPage : Int
    , restaurantSorter : Sorter
    , restaurant : Restaurant.Model

    -- Cars Model
    , car : Car.Model
    , carSorter : Sorter

    -- Golf
    , golfSorter : Sorter
    , debounceGolfQuery : Debounce.Debounce PackageP.Parameters
    , golf : Golf.Model

    -- Destination
    , destinationPage : Destination.Model

    -- RequestPackageFunnel
    , requestPackageFunnel : RequestPackageFunnel.Model

    -- Packages
    -- Date Picker
    , departDatePicker : DP.DatePicker
    , returnDatePicker : DP.DatePicker
    , doubleDatePicker : DPD.DatePicker

    -- UI
    , openPackages : List Int

    -- FORM
    , isSubmitted : Bool
    , priceMode : Money.Mode
    , budget : Maybe Int
    , numberOfPassenger : Maybe Int
    , destinationDebouncer : Debounce.Debounce String
    , flightDestination : SD.Msg CityWithNearestAirport
    , cities : SD.Msg Location.City
    , departDate : Maybe Date
    , returnDate : Maybe Date
    , destination : String
    , onlineTravelAgencies :
        List
            { id : String
            , name : String
            }

    -- FormDetail Dropdown
    , departureCycleDropdown : Status
    , arrivalCycleDropdown : Status
    , starsIsOpen : Status
    , flightSortByState : Status

    -- FormDetailt SearchableDropdown
    , airlineIsBlur : Bool
    , typedAirline : String
    , eventTypeIsBlur : Bool
    , typedRestaurantType : String
    , restaurantTypeIsOpen : Status

    -- Signup Modal
    , modalPage : Int
    , isSignupModalOpen : Bool
    , signUpStatus : SignupStatus
    , email : String
    , callbackUrl : String
    , flags : Flags

    -- My Package Preview Modal
    , myPackageModalOpenOn : Maybe String
    , requestForm : Destination.RequestForm.Model

    -- FEATURES EVENTS
    }


initModel : Nav.Key -> Flags -> Model
initModel key flag =
    let
        ( datePicker, _ ) =
            DP.init

        today =
            Date.fromIsoString flag.date |> Result.withDefault (Date.fromCalendarDate 2019 Time.Jan 1)

        configuration =
            { domain = flag.url
            , language = I18n.fromString flag.language
            , currency =
                if String.isEmpty flag.currency then
                    Money.currencyFromBCP47 flag.language

                else
                    flag.currency |> Money.fromString |> Maybe.withDefault Money.CAD
            , rates = Nothing
            , currentTime = Time.millisToPosix 0
            , authenticator = Authentication.init auth0authorize auth0logout flag.user
            , disabledProviders = flag.disabledProviders
            , today = today
            , brand = flag.brand
            , context = flag.context
            , navkey = key
            , viewport = Nothing
            }

        prismic =
            Prismic.init "https://user-app.prismic.io/api"
    in
    { config = configuration
    , flags = flag
    , callbackUrl = flag.callbackUrl
    , refresh = Refresh.Never
    , overlayHeight = 750.0
    , stickyHeader = False
    , username = "MeWhit"
    , devError = Nothing
    , currentRoute = Route.Home defaultParameters
    , url = Nothing
    , key = key
    , profile = Nothing
    , maybeFrom = Nothing
    , from = SD.Close (SD.Data "" Nothing NotAsked)
    , httpError = Nothing
    , zone = Time.utc
    , today = today
    , isHomeFormOpen = False
    , pageCount = 1
    , loadingMore = Dict.empty
    , shouldReload = True
    , toast = ToastNone
    , modalInputError = ""
    , applicationOpenedAt = Nothing
    , promoOpen = True
    , videoOpen = False
    , adminPage = Admin.defaultModel Admin.Route.Promotion configuration defaultParameters
    , profilPage = Profil.init configuration
    , promotion = NotAsked
    , appPrismic = NotAsked
    , prismic = prismic
    , topDestinations = NotAsked
    , allDestinations = NotAsked
    , featureEvents = NotAsked

    -- Query
    , query = ""

    -- DOM
    , animateLogo = Nothing
    , dropdownOpen = NoOne
    , isEditableTitleActivated = False
    , formItemDropdown =
        [ Component.Car
        , Component.Flight
        , Component.Hotel
        , Component.Event
        , Component.Restaurant
        ]
    , modalMode = MapModal.Result
    , openMenu = False
    , openFilter = False
    , openCurrency = False
    , openDetail = False
    , excerptChoices = []

    -- Sorter
    , isSorterOpen = False

    -- Packages
    , packagePage = 1
    , myPackage = Nothing
    , loadedPackage = NotAsked
    , packageParameters = defaultParameters
    , idToOpenOptions = Nothing

    -- Packages
    , packages = NotAsked

    --
    , flight = Flight.initModel Time.utc configuration defaultParameters
    , onlineTravelAgencies = []
    , flightSorter = Price
    , debounceDestinationQuery = Debounce.init
    , debounceFromQuery = Debounce.init

    -- Home
    , home = Home.defaultModel configuration (flag.width |> toBreakpoint) NotAsked PackageP.defaultParameters

    -- Hotels
    , hotel = Hotel.initModel today configuration defaultParameters
    , hotelPage = 1
    , hotelSorter = Popular
    , displayMap = False
    , onlyAirbnb = False

    -- Event
    , eventPage = 1
    , event = Event.initModel today configuration defaultParameters
    , eventSorter = Popular

    -- Restaurants
    , restaurantPage = 1
    , restaurantSorter = Popular
    , restaurant = Restaurant.initModel configuration defaultParameters Popular

    -- Cars Models
    , car = Car.initModel today configuration defaultParameters
    , carSorter = Price

    -- Golf Model
    , golfSorter = HighestPrice
    , debounceGolfQuery = Debounce.init
    , golf = Golf.initModel configuration defaultParameters

    -- Destination
    , destinationPage = Destination.initModel Time.utc prismic configuration defaultParameters
    , requestPackageFunnel = RequestPackageFunnel.defaultModel configuration

    -- date
    , departDatePicker = datePicker
    , returnDatePicker = datePicker
    , doubleDatePicker = DPD.init |> Tuple.first

    --UI
    , openPackages = []

    -- Searchable Dropdown
    , destinationDebouncer = Debounce.init
    , flightDestination = SD.Close SD.defaultData
    , cities = SD.Close SD.defaultData
    , budget = Nothing
    , numberOfPassenger = Nothing
    , departDate = Nothing
    , returnDate = Nothing
    , destination = ""
    , priceMode = Money.PerPerson

    -- FormDetailState Dropdown
    , departureCycleDropdown = Close
    , arrivalCycleDropdown = Close
    , starsIsOpen = Close
    , flightSortByState = Close
    , isSubmitted = False

    -- Form Detail Searchable DropDown
    , airlineIsBlur = False
    , typedAirline = ""
    , eventTypeIsBlur = False
    , typedRestaurantType = ""
    , restaurantTypeIsOpen = Close

    -- Signup Modal
    , modalPage = 1
    , isSignupModalOpen = False
    , signUpStatus =
        let
            lastDemandIsOlderThan24hours =
                flag.lastestSubscriptionDemanded
                    |> toTime
                    |> Result.map (Date.fromPosix Time.utc)
                    |> Result.mapError (\_ -> "")
                    |> Result.map (Date.add Date.Days 1)
                    |> Result.map2 Date.compare (Date.fromIsoString flag.date)
                    |> Result.withDefault Basics.EQ
                    |> (==) Basics.LT
        in
        if flag.isSubscribed || lastDemandIsOlderThan24hours then
            Yes

        else
            Waiting 5
    , email = ""

    -- My Package Preview Modal
    , myPackageModalOpenOn = Nothing
    , requestForm = Destination.RequestForm.defaultModel configuration

    -- FEATURES EVENTS
    }


type SignupStatus
    = Yes
    | Waiting Int



-- SUBS


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ handleUserInformation HandleProfile
        , auth0authResult (Authentication.handleAuthResult >> AuthenticationMsg)
        , handlePackage mapMyPackage
        , handleScrollOrResize SetOverlay
        , Flight.subscriptions model.flight |> Sub.map FlightMsg
        , case model.packageParameters.airportFrom of
            Just _ ->
                Sub.none

            _ ->
                handleGeoLocation HandlePosition

        -- , case model.eventTypeIsOpen of
        --     Close ->
        --         Sub.none
        --     Open ->
        --         Events.onMouseUp (Decode.succeed <| SetEvenTypeIsOpen Close)
        , if Dict.isEmpty model.loadingMore |> not then
            Time.every oneSecond StopAnimation

          else
            Sub.none
        , case model.signUpStatus of
            Yes ->
                Sub.none

            Waiting _ ->
                Time.every fiveteenSecond (\_ -> ToggleSignupModal)
        , if model.dropdownOpen == NoOne then
            Sub.none

          else
            Events.onMouseDown (outsideTarget model.dropdownOpen)
        , Time.every oneMinute SetCurrentTime
        , case model.refresh of
            Refresh.At time ->
                Time.every time (\_ -> RefreshPage)

            Refresh.Never ->
                Sub.none
        , Sub.map ToProfilPage (Profil.subscription model.profilPage)
        , onResize HandleResize
        ]


oneSecond : Float
oneSecond =
    1000


fiveteenSecond : Float
fiveteenSecond =
    15000


oneMinute : Float
oneMinute =
    60000


outsideTarget : DropdownChoice -> Decode.Decoder Msg
outsideTarget dropdownId =
    Decode.field "target" (isOutsideDropdown <| Component.toId dropdownId)
        |> Decode.andThen
            (\isOutside ->
                if isOutside then
                    Decode.succeed (CloseDropdown dropdownId)

                else
                    Decode.fail "inside dropdown"
            )


isOutsideDropdown : String -> Decode.Decoder Bool
isOutsideDropdown dropdownId =
    Decode.oneOf
        [ Decode.field "id" Decode.string
            |> Decode.andThen
                (\id ->
                    if dropdownId == id then
                        -- found match by id
                        Decode.succeed False

                    else
                        -- try next decoder
                        Decode.fail "check parent node"
                )
        , Decode.lazy (\_ -> isOutsideDropdown dropdownId |> Decode.field "parentNode")

        -- fallback if all previous decoders failed
        , Decode.succeed True
        ]



-- MSG


type alias ScrollReturns =
    { homeHeaderHeight : Float
    }


toMobileViewPort : String -> Model -> Cmd Msg
toMobileViewPort id model =
    case model.config.viewport of
        Just t ->
            if t.viewport.width <= 764 then
                Dom.getElement id
                    |> Task.andThen
                        (\e ->
                            Dom.setViewport 0 (e.element.y - 25)
                        )
                    |> Task.attempt (\_ -> NoOp)

            else
                Cmd.none

        _ ->
            Cmd.none


type Msg
    = ChangedUrl Url
    | ToAdminPage Admin.Msg
    | ToProfilPage Profil.Msg
    | ClickedLink Browser.UrlRequest
    | AuthenticationMsg Authentication.Msg
    | ToDoubleDatePicker DPD.Msg
    | SetZone Time.Zone
    | SetDate Date
    | SetApplicationOpenedAt Time.Posix
    | SetCurrentTime Time.Posix
    | RefreshPage
    | HandleProfile (Maybe Profile)
    | HandlePosition (Maybe ( Float, Float ))
    | SetOverlay ScrollReturns
    | HandleResize Int Int
    | StopAnimation Time.Posix
    | NoOp
    | SetEmail String
    | SubmitEmail
    | SelectCurrency Money.Currency
      -- DOM
    | HandleViewport Dom.Viewport
    | HandleGetAnimateLogo (Result Dom.Error Dom.Element)
    | HandleSticktHeader (Result Dom.Error Dom.Element)
    | CloseDropdown DropdownChoice
    | ToggleTitleEditableMode
    | ToggleFormDropdownItem Component.FormItemDropdown
    | ChangeMapMode MapModal.Mode
    | ToggleMoneyMode
    | CloseToast
    | ToggleFilter
    | ToggleDetail
    | ToggleExcerpt ExcerptChoice
      -- Sorter
    | SorterToggle Bool
    | SetSorter Route.Route Sorter
      -- Package
    | SetPackage MyPackage.MyPackage
    | ToggleCardOption (Maybe String)
    | AddToPackageHideOnMouseLeave
    | SetTitle String
      -- Flight Msg
    | FlightMsg Flight.Msg
    | HandleGetFlightDestination (WebData CityWithNearestAirport)
    | HandleGetFlightFrom (WebData CityWithNearestAirport)
      -- From Msg
    | HandleGetFrom (WebData CityWithNearestAirport)
    | HandleGetFroms (WebData (List CityWithNearestAirport))
      -- Home Msg
    | HomeMsg Home.Msg
      -- Hotels Msg
    | HotelMsg Hotel.Msg
    | DestinationMsg Destination.Msg
    | SetPageCount Int
    | SetDisplayMap ( Bool, Bool )
      -- Cars Msg
    | CarMsg Car.Msg
      -- Events Msg
    | EventMsg Event.Msg
      -- Restaurant Msg
    | RestaurantMsg Restaurant.Msg
      -- Golf Msg
    | GolfMsg Golf.Msg
    | SetGolfQuery String
    | SetGolfMaxPrice String
    | SetGolfMinPrice String
    | SetGolfStars String
    | DebouncedGolfQuery Debounce.Msg
    | DebounceFromQuery Debounce.Msg
    | ResetGolfFilter
      -- My Package
    | AddSelectedHotel Hotel.Hotel
    | AddSelectFlight Flight.Flight
    | AddSelectedEvent Event.Activities
    | AddSelectedRestaurant Restaurant.RestaurantType
    | AddSelectedCar Car.CarRental
    | AddSelectedGolf Golf.Club
    | RemoveFlight String
    | RemoveHotel String
    | RemoveEvent String
    | RemoveRestaurant String
    | RemoveCar String
    | RemoveGolf String
    | HandleMyPackageUpdate (WebData MyPackage.MyPackage)
    | HandleMyPackage (WebData MyPackage.MyPackage)
      -- General
    | HandleRates (WebData Rates.Rates)
    | HandlePromotion (WebData Promotion)
    | HandlePrismicApp (RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response AppDocType ))
    | HandleTopDestinations (RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType ))
    | HandleAllDestinations (RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType ))
    | HandleFeatureEvents (RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType ))
      -- SignupModal
    | ToggleSignupModal
    | CloseSignupModal
      -- MyPackagePreviewModal
    | OpenMyPackagePreviewModal String
    | CloseMyPackagePreviewModal
      -- DEV TOOLING
    | SetError Decode.Error
    | SetModalPageCount Int
    | HandleSubscribeEmail (Result Http.Error ())
    | HandleDropdownClick String
    | RequestForm Destination.RequestForm.Msg
    | ToRequestPackageFunnel RequestPackageFunnel.Msg



-- Footer
-- | NoOp


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        RequestForm subMsg ->
            let
                ( requestFormModel, requestFormSub ) =
                    Destination.RequestForm.update subMsg model.requestForm
            in
            ( { model | requestForm = requestFormModel }, requestFormSub |> Cmd.map RequestForm )

        ToRequestPackageFunnel subMsg ->
            let
                ( requestPackageModel, requestPacakgeSub ) =
                    RequestPackageFunnel.update subMsg model.requestPackageFunnel
            in
            ( { model | requestPackageFunnel = requestPackageModel }, requestPacakgeSub |> Cmd.map ToRequestPackageFunnel )

        ToAdminPage subMsg ->
            let
                ( adminModel, adminSub ) =
                    Admin.update subMsg model.adminPage
            in
            ( { model | adminPage = adminModel }, adminSub |> Cmd.map ToAdminPage )

        ToProfilPage subMsg ->
            let
                ( profilModel, profilMsg ) =
                    Profil.update subMsg model.profilPage
            in
            ( { model | profilPage = profilModel }, profilMsg |> Cmd.map ToProfilPage )

        AuthenticationMsg authMsg ->
            let
                ( authModel, cmd ) =
                    Authentication.update authMsg model.config.authenticator

                cmdnav =
                    case authMsg of
                        Authentication.AuthenticationResult _ ->
                            Nav.replaceUrl model.key model.callbackUrl

                        _ ->
                            Cmd.none

                config =
                    Config.setUser authModel model.config
            in
            ( { model | config = config }, Cmd.batch [ cmdnav, Cmd.map AuthenticationMsg cmd, MyPackage.fetchMyPackage config HandleMyPackage ] )

        SelectCurrency currency ->
            let
                config =
                    model.config

                newConfig =
                    { config | currency = currency }
            in
            case model.config.rates of
                Just rates ->
                    ( { model
                        | config = newConfig
                        , shouldReload = True
                        , openCurrency = False
                        , myPackage = model.myPackage |> Maybe.map (MyPackage.convertPrice rates model.config.currency currency)
                      }
                    , Cmd.batch
                        [ saveCurrency (Money.currencyToString currency)
                        , Nav.replaceUrl model.config.navkey (Route.toUrl <| Route.changeParams model.packageParameters <| model.currentRoute)
                        ]
                    )

                Nothing ->
                    ( { model | toast = ToastError "Convert currency fail" }, Cmd.none )

        SetEmail str ->
            ( { model | email = str }, Cmd.none )

        SubmitEmail ->
            ( model, Cmd.batch [ Http.post { url = model.config.domain ++ "/mails", body = Http.stringBody "text/plain" model.email, expect = Http.expectWhatever HandleSubscribeEmail } ] )

        NoOp ->
            ( model, Cmd.none )

        HandleSubscribeEmail t ->
            case t of
                Ok _ ->
                    ( { model | email = "", toast = ToastSuccess "You have been subscribed to our newsletter", isSignupModalOpen = False }, isSubscribed True )

                Err _ ->
                    ( { model | email = "", toast = ToastSuccess "Your email has already been subscribed to our newsletter", isSignupModalOpen = False }, isSubscribed True )

        SetError error ->
            ( { model | devError = Just error }, Cmd.none )

        SetOverlay scoll ->
            ( { model | overlayHeight = scoll.homeHeaderHeight }
            , Cmd.batch
                [ Dom.getViewport |> Task.perform HandleViewport

                -- , Dom.getElement "animate-logo" |> Task.attempt HandleGetAnimateLogo
                -- , Dom.getElement "sticky-header" |> Task.attempt HandleSticktHeader
                ]
            )

        HandleResize w _ ->
            let
                home =
                    model.home
            in
            ( { model | home = { home | breakpoint = toBreakpoint w } }, Cmd.none )

        ChangedUrl url ->
            let
                ( model2, cmd ) =
                    changeRoute url model

                ( pageCount, shouldGoTop ) =
                    if model2.currentRoute == model.currentRoute then
                        ( model2.pageCount, [] )

                    else
                        ( 1, [ Task.perform (\_ -> NoOp) (Dom.setViewport 0 0), Task.perform SetApplicationOpenedAt Time.now ] )
            in
            ( { model2 | pageCount = pageCount, query = "", openMenu = False, myPackageModalOpenOn = Nothing, url = Just url }
            , Cmd.batch
                ([ cmd
                 , Dom.getElement "animate-logo" |> Task.attempt HandleGetAnimateLogo
                 , toggleCarousel True
                 , onRouteChange ()
                 , onForceCloseMobileMenu ()
                 ]
                    ++ shouldGoTop
                )
            )

        StopAnimation _ ->
            let
                loadingMore =
                    Dict.map (\_ -> \( timeLeft, maxTime, _ ) -> ( timeLeft + 1, maxTime, True )) model.loadingMore
            in
            ( { model | loadingMore = loadingMore }, Cmd.none )

        -- DOM
        ToggleFilter ->
            ( { model | openFilter = not model.openFilter }, toggleFilter False )

        ToggleDetail ->
            ( { model | openDetail = not model.openDetail }, Cmd.none )

        ToggleExcerpt choice ->
            if List.member choice model.excerptChoices then
                ( { model | excerptChoices = model.excerptChoices |> List.filter (\s -> s == choice |> not) }, Cmd.none )

            else
                ( { model | excerptChoices = choice :: model.excerptChoices }, Cmd.none )

        CloseToast ->
            ( { model | toast = ToastNone }, Cmd.none )

        ChangeMapMode to ->
            ( { model | modalMode = to }, Cmd.none )

        HandleViewport viewport ->
            ( { model | config = Config.setViewport viewport model.config }, onViewportLoaded () )

        HandleGetAnimateLogo v ->
            ( { model | animateLogo = Result.toMaybe v }, Cmd.none )

        HandleSticktHeader rViewport ->
            if model.displayMap then
                ( { model | stickyHeader = False }, Cmd.none )

            else
                rViewport
                    |> Result.map (\viewport -> ( { model | stickyHeader = viewport.viewport.y > viewport.element.y }, Cmd.none ))
                    |> Result.withDefault ( model, Cmd.none )

        ToggleTitleEditableMode ->
            if model.isEditableTitleActivated then
                ( { model | isEditableTitleActivated = not model.isEditableTitleActivated }, Cmd.none )

            else
                ( { model | isEditableTitleActivated = not model.isEditableTitleActivated }, Task.attempt (\_ -> NoOp) (Dom.focus "package-title-rename") )

        ToggleFormDropdownItem formItem ->
            let
                newList =
                    \t ->
                        if model.formItemDropdown |> List.any ((==) t) then
                            model.formItemDropdown |> List.filter (\s -> not <| t == s)

                        else
                            t :: model.formItemDropdown
            in
            ( { model | formItemDropdown = newList formItem }, Cmd.none )

        ToggleMoneyMode ->
            ( { model
                | priceMode =
                    case model.priceMode of
                        Money.PerPerson ->
                            Money.ForGroup

                        Money.ForGroup ->
                            Money.PerPerson
              }
            , Cmd.none
            )

        ToDoubleDatePicker subMsg ->
            let
                ( newDatePicker, dateEvent, shouldFocus ) =
                    DPD.update DPD.defaultSettings subMsg model.doubleDatePicker

                move =
                    if shouldFocus then
                        toMobileViewPort (Component.toId Component.DoubleDateCalendar) model

                    else
                        Cmd.none

                ( startDate, endDate ) =
                    case dateEvent of
                        DPD.Picked ( d, d2, _ ) ->
                            ( d
                            , d2
                            )

                        DPD.FailedInput _ ->
                            ( Nothing, Nothing )

                        DPD.None ->
                            ( model.departDate, model.returnDate )
            in
            ( { model
                | departDate = startDate
                , returnDate = endDate
                , doubleDatePicker = newDatePicker
                , dropdownOpen =
                    if DPD.isOpen newDatePicker then
                        Component.DoubleDateCalendar

                    else
                        NoOne
                , packageParameters =
                    model.packageParameters
                        |> PackageP.setReturnDate endDate
                        |> PackageP.setDepartureDate startDate
                        |> PackageP.setEvent (model.packageParameters.event |> PackageP.setEventMaxDate endDate |> PackageP.setEventMinDate startDate)
                        |> PackageP.setHotel (model.packageParameters.hotel |> PackageP.setHotelCheckout endDate |> PackageP.setHotelCheckin startDate)
                        |> PackageP.setCar (model.packageParameters.car |> PackageP.setCarDropoffDate endDate |> PackageP.setCarPickupDate startDate)
                        |> PackageP.setRestaurant (model.packageParameters.restaurant |> PackageP.setRestaurantdinningDate startDate)
              }
            , move
            )

        ClickedLink urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    case url.fragment of
                        Just _ ->
                            ( model, Cmd.none )

                        Nothing ->
                            ( model, Nav.pushUrl model.key <| Url.toString url )

                Browser.External href ->
                    ( model, Nav.load href )

        SetZone zone ->
            ( { model | zone = zone }, Cmd.none )

        SetDate date ->
            ( { model
                | today = date
                , departDatePicker = DP.initFromDate date
              }
            , Cmd.none
            )

        SetApplicationOpenedAt posix ->
            ( { model | applicationOpenedAt = Just posix, config = Config.setCurrentTime posix model.config }, Cmd.none )

        SetCurrentTime posix ->
            ( { model | config = Config.setCurrentTime posix model.config }, Cmd.none )

        RefreshPage ->
            ( model, Nav.replaceUrl model.key (Route.toUrl model.currentRoute) )

        HandleProfile maybeProfile ->
            ( { model | profile = maybeProfile }
            , Nav.replaceUrl model.key <|
                if String.isEmpty model.callbackUrl then
                    "/"

                else
                    model.callbackUrl
            )

        HandlePosition maybeLatLong ->
            case maybeLatLong of
                Just ( lat, long ) ->
                    ( { model | maybeFrom = maybeLatLong }, Cmd.batch [ getCityByCoordinateAndNearestAirport model.config ( lat, long ) HandleGetFrom ] )

                Nothing ->
                    ( { model | maybeFrom = maybeLatLong }, Cmd.none )

        -- SORTER
        SorterToggle value ->
            ( { model | isSorterOpen = value }, Cmd.none )

        SetSorter route value ->
            case route of
                Route.Hotels _ ->
                    ( { model | hotelSorter = value, isSorterOpen = False }, Cmd.none )

                Route.Flights _ ->
                    ( { model | flightSorter = value, isSorterOpen = False }, Cmd.none )

                Route.Events _ ->
                    ( { model | eventSorter = value, isSorterOpen = False }, Cmd.none )

                Route.Golf _ ->
                    ( { model | golfSorter = value, isSorterOpen = False }
                    , Cmd.none
                    )

                Route.Cars _ ->
                    ( { model | carSorter = value, isSorterOpen = False }, Cmd.none )

                Route.Restaurant _ ->
                    ( { model
                        | restaurantSorter = value
                        , isSorterOpen = False
                      }
                    , Cmd.none
                      -- , case Restaurant.canFetch (Restaurant.toRestaurantParameters model.packageParameters 0 value) of
                      --     Just p ->
                      --         Restaurant.getRestaurants p HandleRestaurants
                      --     Nothing ->
                      --         Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        -- PACKAGES
        SetTitle title ->
            let
                updatedPackage =
                    model.myPackage |> Maybe.map (\p -> { p | name = title })
            in
            ( { model | myPackage = updatedPackage }, updatedPackage |> Maybe.map (\s -> [ savePackage False model.config s ]) |> Maybe.withDefault [] |> Cmd.batch )

        SetPackage pack ->
            ( { model | myPackage = Just pack }, Cmd.batch [ savePackage False model.config pack ] )

        ToggleCardOption index ->
            let
                shouldClose =
                    Maybe.map2 (\i -> \i2 -> i == i2) index model.idToOpenOptions |> Maybe.withDefault False
            in
            ( { model
                | idToOpenOptions =
                    if shouldClose then
                        Nothing

                    else
                        index
              }
            , Cmd.none
            )

        AddToPackageHideOnMouseLeave ->
            ( { model
                | idToOpenOptions =
                    Nothing
              }
            , Cmd.none
            )

        DestinationMsg destMsg ->
            let
                ( dest, newDestMsg ) =
                    Destination.update destMsg model.destinationPage
            in
            ( { model | destinationPage = dest }, Cmd.map DestinationMsg newDestMsg )

        -- Flight Msg
        FlightMsg flightMsg ->
            let
                ( flight, newFlightMsg ) =
                    Flight.update flightMsg model.flight
            in
            ( { model
                | flight = flight
                , packageParameters =
                    model.packageParameters
                        |> PackageP.setFlight
                            { stopOvers = flight.filters.parameters.stopOvers
                            , airlines = flight.filters.parameters.airlines |> List.map (\s -> ( s, s )) |> Dict.fromList |> Just
                            , departureTimeMin = flight.filters.parameters.departureTimeMin
                            , departureTimeMax = flight.filters.parameters.departureTimeMax
                            , arrivalTimeMin = flight.filters.parameters.arrivalTimeMin
                            , arrivalTimeMax = flight.filters.parameters.arrivalTimeMax
                            , returnDepartureTimeMin = flight.filters.parameters.returnDepartureTimeMin
                            , returnDepartureTimeMax = flight.filters.parameters.returnDepartureTimeMax
                            , returnArrivalTimeMin = flight.filters.parameters.returnArrivalTimeMin
                            , returnArrivalTimeMax = flight.filters.parameters.returnArrivalTimeMax
                            , minPrice = flight.filters.parameters.minPrice
                            , maxPrice = flight.filters.parameters.maxPrice
                            , flightType = flight.callToAction.form.flightType
                            }
              }
            , Cmd.map FlightMsg newFlightMsg
            )

        GolfMsg golfMsg ->
            let
                ( golf, newGolfMsg ) =
                    Golf.update golfMsg model.golf
            in
            ( { model | golf = golf }, newGolfMsg |> Cmd.map GolfMsg )

        HomeMsg homeMsg ->
            let
                ( home, newHomeMsg ) =
                    Home.update homeMsg model.home
            in
            ( { model | home = home }, newHomeMsg |> Cmd.map HomeMsg )

        HotelMsg hotelMsg ->
            let
                ( hotel, newHotelMsg ) =
                    Hotel.update hotelMsg model.hotel
            in
            ( { model
                | hotel = hotel
                , packageParameters =
                    model.packageParameters
                        |> PackageP.setNumberOfRooms hotel.filters.rooms
                        |> PackageP.setHotel
                            { query = hotel.filters.query |> String.split ","
                            , checkin = hotel.filters.checkin
                            , checkout = hotel.filters.checkout
                            , stars = hotel.filters.stars
                            , minPricePerNight = hotel.filters.minPricePerNight
                            , maxPricePerNight = hotel.filters.maxPricePerNight
                            , minGuestRating = hotel.filters.minGuestRating
                            , maxGuestRating = hotel.filters.maxGuestRating
                            , providers = hotel.filters.providers
                            , propertyType = hotel.filters.propertyType
                            }
              }
            , Cmd.map HotelMsg newHotelMsg
            )

        HandleGetFlightDestination dest ->
            case RemoteData.toMaybe dest of
                Just cityAirport ->
                    ( { model
                        | flightDestination =
                            model.flightDestination
                                |> SD.close
                                |> SD.update (SD.SetInput <| Location.CityWithNearestAirport.toCityString cityAirport.city)
                                |> SD.update (SD.SetSelected <| Just cityAirport)
                        , destination = Location.CityWithNearestAirport.toCityString cityAirport.city
                      }
                    , Cmd.none
                    )

                Nothing ->
                    ( model, Cmd.none )

        HandleGetFlightFrom from ->
            case RemoteData.toMaybe from of
                Just cityAirport ->
                    ( { model
                        | from =
                            model.from
                                |> SD.close
                                |> SD.update (SD.SetInput <| Location.CityWithNearestAirport.toCityString cityAirport.city)
                                |> SD.update (SD.SetSelected <| Just cityAirport)
                      }
                    , Cmd.none
                    )

                Nothing ->
                    ( model, Cmd.none )

        CloseDropdown dp ->
            let
                ( newDatePicker, _, _ ) =
                    DPD.update DPD.defaultSettings DPD.close model.doubleDatePicker
            in
            ( { model
                | dropdownOpen = NoOne
                , doubleDatePicker = newDatePicker
                , flightDestination =
                    if dp == Component.To then
                        model.flightDestination |> SD.close

                    else
                        model.flightDestination
              }
            , Cmd.none
            )

        HandleGetFroms froms ->
            ( { model | from = SD.update (SD.SetChoices froms) model.from }, Cmd.none )

        DebounceFromQuery query ->
            let
                ( debounce, cmd ) =
                    Debounce.update
                        homeDebouncerConfig
                        (Debounce.takeLast <| \q -> getCitiesAndNearestAirports model.config.domain q HandleGetFroms)
                        query
                        model.debounceFromQuery
            in
            ( { model
                | from =
                    SD.update
                        (SD.SetChoices
                            (if SD.choices model.from |> RemoteData.isSuccess then
                                SD.choices model.from

                             else
                                Loading
                            )
                        )
                        model.from
                , debounceFromQuery = debounce
              }
            , cmd
            )

        HandleRates rates ->
            ( { model | config = Config.setRates (RemoteData.toMaybe rates) model.config }, Cmd.none )

        HandlePromotion promotion ->
            ( { model | promotion = promotion }, Cmd.none )

        HandlePrismicApp rPrisimiApp ->
            case rPrisimiApp of
                Success ( prismic, response ) ->
                    let
                        ( model2, cmd ) =
                            model.url
                                |> Maybe.map
                                    (\s ->
                                        changeRoute s
                                            { model
                                                | prismic =
                                                    Prismic.cache model.prismic prismic
                                                , appPrismic =
                                                    response.results |> List.head |> RemoteData.fromMaybe (Prismic.DecodeDocumentError "doesnt find any results")
                                            }
                                    )
                                |> Maybe.withDefault ( model, Cmd.none )
                    in
                    ( model2
                    , Cmd.batch
                        [ fetchTopDestinations prismic HandleTopDestinations
                        , cmd
                        , fetchFeaturesEvents prismic HandleFeatureEvents

                        -- , fetchAllDestinations model.prismic HandleAllDestinations
                        ]
                      -- Nav.pushUrl model.key <| Route.toUrl model.currentRoute
                    )

                Failure e ->
                    ( { model | appPrismic = Failure e }, Cmd.none )

                NotAsked ->
                    ( { model | appPrismic = NotAsked }, Cmd.none )

                Loading ->
                    ( { model | appPrismic = Loading }, Cmd.none )

        HandleTopDestinations r ->
            ( { model | topDestinations = r }, Cmd.none )

        HandleAllDestinations r ->
            ( { model | allDestinations = r }, Cmd.none )

        HandleGetFrom res ->
            case RemoteData.toMaybe res of
                Just { city, airport } ->
                    let
                        ( flight, flightMsg ) =
                            Flight.update (Flight.HandleAirportFrom res) model.flight

                        ( destination, destinationMsg ) =
                            Destination.update (Destination.HandleAirportFrom res) model.destinationPage
                    in
                    ( { model
                        | flight = flight
                        , destinationPage = destination
                        , config =
                            if String.isEmpty model.flags.currency then
                                model.config |> Config.setCurrencyByCountry city.countryCode

                            else
                                model.config
                        , packageParameters = model.packageParameters |> PackageP.setAirportFrom (Just { name = city.name, lat = city.lat, long = city.long, countryCode = city.countryCode, iata = airport.code })
                      }
                    , Cmd.batch [ Cmd.map FlightMsg flightMsg, Cmd.map DestinationMsg destinationMsg ]
                    )

                Nothing ->
                    ( model, Cmd.none )

        CarMsg carMsg ->
            let
                ( car, newCarMsg ) =
                    Car.update carMsg model.car
            in
            ( { model | car = car, packageParameters = model.packageParameters |> PackageP.setCar car.packageParameters.car }, Cmd.map CarMsg newCarMsg )

        EventMsg eventMsg ->
            let
                ( event, eMsg ) =
                    Event.update eventMsg model.event
            in
            ( { model | event = event, packageParameters = model.packageParameters |> PackageP.setEvent event.packageParameters.event }, Cmd.map EventMsg eMsg )

        RestaurantMsg rMsg ->
            let
                ( resto, nrMsg ) =
                    Restaurant.update rMsg model.restaurant
            in
            ( { model | restaurant = resto }, Cmd.map RestaurantMsg nrMsg )

        -- Hotels
        SetDisplayMap ( map, air ) ->
            ( { model | displayMap = map, onlyAirbnb = air, openFilter = False }, Dom.getElement "sticky-header" |> Task.attempt HandleSticktHeader )

        SetPageCount pageNumber ->
            ( { model | pageCount = pageNumber }, Cmd.none )

        SetModalPageCount pageNumber ->
            ( { model | modalPage = pageNumber }, Cmd.none )

        -- GOLF MSG
        SetGolfQuery value ->
            let
                newParams =
                    model.packageParameters |> PackageP.setGolf (model.packageParameters.golf |> PackageP.setGolfQuery value)

                ( debounce, cmd ) =
                    Debounce.push golfQueryDebouncerConfig newParams model.debounceGolfQuery

                baseModel =
                    model.golf
            in
            ( { model
                | packageParameters = newParams
                , debounceGolfQuery = debounce
                , golf = { baseModel | clubs = model.golf.originalClubs |> RemoteData.map (golfFilters newParams) }
              }
            , Cmd.batch [ cmd ]
            )

        SetGolfMaxPrice value ->
            let
                newParams =
                    model.packageParameters
                        |> PackageP.setGolf (model.packageParameters.golf |> PackageP.setGolfMaxPrice (value |> String.toInt))

                baseModel =
                    model.golf
            in
            ( { model
                | packageParameters = newParams
                , golf = { baseModel | clubs = model.golf.originalClubs |> RemoteData.map (golfFilters newParams) }
              }
            , Cmd.none
            )

        SetGolfMinPrice value ->
            let
                newParams =
                    model.packageParameters
                        |> PackageP.setGolf (model.packageParameters.golf |> PackageP.setGolfMinPrice (value |> String.toInt))

                baseModel =
                    model.golf
            in
            ( { model
                | packageParameters = newParams
                , golf = { baseModel | clubs = model.golf.originalClubs |> RemoteData.map (golfFilters newParams) }
              }
            , Cmd.none
            )

        SetGolfStars star ->
            let
                newParams =
                    model.packageParameters
                        |> PackageP.setGolf (model.packageParameters.golf |> PackageP.setGolfStars (String.toInt star))

                baseModel =
                    model.golf
            in
            ( { model
                | golf = { baseModel | clubs = model.golf.originalClubs |> RemoteData.map (golfFilters newParams) }
              }
            , Cmd.none
            )

        DebouncedGolfQuery _ ->
            ( model, Cmd.none )

        -- let
        --     ( debounce, cmd ) =
        --         Debounce.update
        --             golfQueryDebouncerConfig
        --             (Debounce.takeLast (\param -> Golf.search model.config (Golf.toGolfParameters model.pageCount param model.golfSorter) HandleGolfClubs))
        --             t
        --             model.debounceGolfQuery
        -- -- in
        -- ( { model | debounceGolfQuery = debounce }
        -- , cmd
        -- )
        ResetGolfFilter ->
            let
                baseModel =
                    model.golf
            in
            ( { model
                | packageParameters = model.packageParameters |> PackageP.setGolf PackageP.defaultGolf
                , golf = { baseModel | clubs = model.golf.originalClubs }
              }
            , Cmd.none
            )

        --  Package Msg
        AddSelectedHotel value ->
            let
                ( _, maybePackage ) =
                    case model.myPackage of
                        Just myPackage ->
                            MyPackage.addHotel model.config.currentTime (Success value) myPackage

                        Nothing ->
                            MyPackage.addHotel model.config.currentTime (Success value) MyPackage.defaultMyPackage
            in
            ( { model | myPackage = Just maybePackage }
            , savePackage True model.config maybePackage
            )

        RemoveHotel id ->
            let
                mypackage =
                    model.myPackage |> Maybe.map (\p -> { p | hotel = Dict.remove id p.hotel })
            in
            ( { model | myPackage = mypackage }
            , mypackage |> Maybe.map (\s -> savePackage True model.config s) |> Maybe.withDefault Cmd.none
            )

        AddSelectFlight value ->
            let
                ( _, maybePackage ) =
                    case model.myPackage of
                        Just myPackage ->
                            MyPackage.addFlight model.config.currentTime (Success value) myPackage

                        Nothing ->
                            MyPackage.addFlight model.config.currentTime (Success value) MyPackage.defaultMyPackage
            in
            ( { model | myPackage = Just maybePackage }
            , savePackage True model.config maybePackage
            )

        RemoveFlight id ->
            let
                mypackage =
                    model.myPackage |> Maybe.map (\p -> { p | flight = Dict.remove id p.flight })
            in
            ( { model | myPackage = mypackage }
            , mypackage |> Maybe.map (\s -> savePackage True model.config s) |> Maybe.withDefault Cmd.none
            )

        AddSelectedEvent value ->
            let
                ( _, maybePackage ) =
                    case model.myPackage of
                        Just myPackage ->
                            MyPackage.addEvent model.config.currentTime (Success value) myPackage

                        Nothing ->
                            MyPackage.addEvent model.config.currentTime (Success value) MyPackage.defaultMyPackage
            in
            ( { model | myPackage = Just maybePackage }
            , savePackage True model.config maybePackage
            )

        RemoveEvent id ->
            let
                mypackage =
                    model.myPackage |> Maybe.map (\p -> { p | event = Dict.remove id p.event })
            in
            ( { model | myPackage = mypackage }
            , mypackage |> Maybe.map (\s -> savePackage True model.config s) |> Maybe.withDefault Cmd.none
            )

        AddSelectedRestaurant value ->
            let
                ( _, maybePackage ) =
                    case model.myPackage of
                        Just myPackage ->
                            MyPackage.addRestaurant model.config.currentTime (Success value) myPackage

                        Nothing ->
                            MyPackage.addRestaurant model.config.currentTime (Success value) MyPackage.defaultMyPackage
            in
            ( { model | myPackage = Just maybePackage }
            , savePackage True model.config maybePackage
            )

        RemoveRestaurant id ->
            let
                mypackage =
                    model.myPackage |> Maybe.map (\p -> { p | restaurant = Dict.remove id p.restaurant })
            in
            ( { model | myPackage = mypackage }
            , mypackage |> Maybe.map (\s -> savePackage True model.config s) |> Maybe.withDefault Cmd.none
            )

        AddSelectedCar value ->
            let
                ( _, maybePackage ) =
                    case model.myPackage of
                        Just myPackage ->
                            MyPackage.addCar model.config.currentTime (Success value) myPackage

                        Nothing ->
                            MyPackage.addCar model.config.currentTime (Success value) MyPackage.defaultMyPackage
            in
            ( { model | myPackage = Just maybePackage }
            , savePackage True model.config maybePackage
            )

        RemoveCar id ->
            let
                mypackage =
                    model.myPackage |> Maybe.map (\p -> { p | car = Dict.remove id p.car })
            in
            ( { model | myPackage = mypackage }
            , mypackage |> Maybe.map (\s -> savePackage True model.config s) |> Maybe.withDefault Cmd.none
            )

        AddSelectedGolf value ->
            let
                ( _, maybePackage ) =
                    case model.myPackage of
                        Just myPackage ->
                            MyPackage.addGolf model.config.currentTime (Success value) myPackage

                        Nothing ->
                            MyPackage.addGolf model.config.currentTime (Success value) MyPackage.defaultMyPackage
            in
            ( { model | myPackage = Just maybePackage }
            , savePackage True model.config maybePackage
            )

        RemoveGolf id ->
            let
                mypackage =
                    model.myPackage |> Maybe.map (\p -> { p | golf = Dict.remove id p.golf })
            in
            ( { model | myPackage = mypackage }
            , mypackage |> Maybe.map (\s -> savePackage True model.config s) |> Maybe.withDefault Cmd.none
            )

        ToggleSignupModal ->
            ( { model | isSignupModalOpen = not model.isSignupModalOpen, signUpStatus = Yes }, Cmd.none )

        CloseSignupModal ->
            ( { model | isSignupModalOpen = False, signUpStatus = Yes }, isSubscribed False )

        OpenMyPackagePreviewModal service ->
            ( { model | myPackageModalOpenOn = Just service }, Cmd.none )

        CloseMyPackagePreviewModal ->
            ( { model | myPackageModalOpenOn = Nothing }, Cmd.none )

        HandleDropdownClick str ->
            ( model, onDropdownClick str )

        HandleFeatureEvents featureEvents ->
            ( { model | featureEvents = featureEvents }, Cmd.none )

        HandleMyPackageUpdate myPackage ->
            ( { model | myPackage = myPackage |> RemoteData.map Just |> RemoteData.withDefault Nothing, loadedPackage = myPackage }, Cmd.none )

        HandleMyPackage myPackage ->
            ( { model
                | myPackage =
                    myPackage
                        |> RemoteData.map Just
                        |> RemoteData.withDefault Nothing
                , loadedPackage = myPackage
              }
            , savePackageDropdown ( myPackage |> RemoteData.map MyPackage.encodeMyPackage |> RemoteData.withDefault (Encode.object []), Basics.False )
            )


savePackage : Bool -> Config.Config -> MyPackage.MyPackage -> Cmd Msg
savePackage shouldOpenCart config package =
    case package.id of
        Just _ ->
            Cmd.batch [ MyPackage.updateMyPackage config package HandleMyPackageUpdate, savePackageDropdown ( MyPackage.encodeMyPackage package, shouldOpenCart ) ]

        Nothing ->
            Cmd.batch [ MyPackage.updateMyPackage config package HandleMyPackageUpdate, savePackageDropdown ( MyPackage.encodeMyPackage package, shouldOpenCart ) ]


getCityByCoordinateAndNearestAirport : Config.Config -> ( Float, Float ) -> (WebData CityWithNearestAirport -> Msg) -> Cmd Msg
getCityByCoordinateAndNearestAirport config param cmd =
    Location.getByCoordinateTask config.domain param
        |> Task.andThen
            (\location ->
                Location.Airports.getOneTasks config.domain { lat = location.lat, long = location.long, countryCode = location.countryCode }
                    |> Task.map (\airport -> { city = location, airport = airport })
            )
        |> RemoteData.asCmd
        |> Cmd.map cmd


getCityByIdAndNearestAirportTask : Config.Config -> { id : String, name : String } -> Task.Task Error CityWithNearestAirport
getCityByIdAndNearestAirportTask config param =
    Location.getByIdTask config.domain param
        |> Task.andThen
            (\location ->
                Location.Airports.getOneTasks config.domain { lat = location.lat, long = location.long, countryCode = location.countryCode }
                    |> Task.map (\airport -> { city = location, airport = airport })
            )


getCityByIdAndNearestAirport : Config.Config -> { id : String, name : String } -> (WebData CityWithNearestAirport -> Msg) -> Cmd Msg
getCityByIdAndNearestAirport config param cmd =
    getCityByIdAndNearestAirportTask config param
        |> RemoteData.asCmd
        |> Cmd.map cmd



-- AUTH


type alias Profile =
    { email : String
    , picture : String
    , name : String
    }



-- ROUTER


mapMyPackage : Encode.Value -> Msg
mapMyPackage value =
    case Decode.decodeValue MyPackage.decodeMyPackage value of
        Ok model ->
            SetPackage model

        Err err ->
            SetError err


changeRoute : Url -> Model -> ( Model, Cmd Msg )
changeRoute url model =
    case Route.fromUrl url of
        Just (Route.Home parameters) ->
            let
                ( homeModel, cmdmsg ) =
                    Home.initPage
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .home
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        parameters
                        model.home
            in
            ( { model
                | currentRoute = Route.Home parameters
                , refresh = Refresh.Never
                , home = homeModel
                , packageParameters = parameters
              }
            , Cmd.batch [ toggleCarousel True, cmdmsg |> Cmd.map HomeMsg ]
            )

        Just (Route.Restaurant parameters) ->
            let
                baseModel =
                    { model
                        | currentRoute = Route.Restaurant parameters
                        , refresh = Refresh.Never
                        , departDate = toDefaultDate parameters.departureDate parameters.restaurant.dinningDate
                        , returnDate = parameters.returnDate
                        , packageParameters = parameters
                        , restaurantPage = 1
                        , destination = parameters.destination |> Maybe.map .name |> Maybe.withDefault ""
                        , formItemDropdown =
                            [ Component.PriceFilter
                            , Component.Cuisine
                            , Component.Category
                            , Component.Establishment
                            ]
                        , flightDestination =
                            model.flightDestination
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toAirportAndCity parameters |> SD.SetSelected)
                        , cities =
                            model.cities
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toCity parameters |> SD.SetSelected)
                    }

                ( restaurant, restaurantMsg ) =
                    Restaurant.initPage baseModel.packageParameters
                        model.restaurantSorter
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .restaurant
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        (Restaurant.initModel baseModel.config baseModel.packageParameters model.restaurantSorter)
            in
            ( { baseModel | restaurant = restaurant }, Cmd.batch [ restaurantMsg |> Cmd.map RestaurantMsg, Card.Step.initComponent "Restaurant" ] )

        Just (Route.Golf params) ->
            let
                baseModel =
                    { model
                        | currentRoute = Route.Golf params
                        , refresh = Refresh.Never
                        , returnDate = params.returnDate
                        , packageParameters = params
                        , departDate = params.departureDate
                        , destination = params.destination |> Maybe.map .name |> Maybe.withDefault ""
                        , formItemDropdown =
                            [ Component.PriceGolf
                            , Component.StarsGolf
                            ]
                        , flightDestination =
                            model.flightDestination
                                |> SD.update (params.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toAirportAndCity params |> SD.SetSelected)
                        , cities =
                            model.cities
                                |> SD.update (params.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toCity params |> SD.SetSelected)
                    }

                ( golf, golfMsg ) =
                    Golf.initPage
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .golf
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        (Golf.initModel baseModel.config baseModel.packageParameters)
            in
            if PackageP.hasTo params then
                ( { baseModel | golf = golf }, Cmd.batch [ golfMsg |> Cmd.map GolfMsg, Card.Step.initComponent "tee time" ] )

            else
                ( { baseModel | golf = golf }, Cmd.batch [ golfMsg |> Cmd.map GolfMsg, Card.Step.initComponent "tee time" ] )

        Just (Route.Hotels parameters) ->
            let
                cityCmd =
                    fetchCityCmd model.config parameters

                baseModel =
                    { model
                        | currentRoute = Route.Hotels parameters
                        , refresh = Refresh.inOneHour
                        , hotelPage = 1
                        , departDate = parameters.hotel.checkin
                        , returnDate = parameters.hotel.checkout
                        , packageParameters = parameters
                        , destination = parameters.destination |> Maybe.map .name |> Maybe.withDefault ""
                        , formItemDropdown =
                            [ Component.PriceHotel
                            , Component.StarsHotel
                            , Component.RatingHotel
                            , Component.ProviderHotel
                            , Component.PropertyType
                            , Component.VacationRentalBedRoom
                            ]
                        , flightDestination =
                            model.flightDestination
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toAirportAndCity parameters |> SD.SetSelected)
                        , cities =
                            model.cities
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toCity parameters |> SD.SetSelected)
                    }

                ( hotel, hotelMsg ) =
                    Hotel.initPage
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .stay
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        (Hotel.initModel baseModel.today baseModel.config baseModel.packageParameters)
            in
            ( { baseModel | hotel = hotel }, Cmd.batch [ Cmd.map HotelMsg hotelMsg, cityCmd, Card.Step.initComponent "stay" ] )

        Just (Route.Flights parameters) ->
            let
                -- cityCmd =
                --     fetchCityCmd model.config parameters
                -- flightParam =
                --     toFlightParameters parameters
                fromCmd =
                    case parameters.airportFrom of
                        Just { name } ->
                            getCityByIdAndNearestAirport model.config { id = "", name = name } HandleGetFlightFrom

                        _ ->
                            Cmd.none

                baseModel =
                    { model
                        | currentRoute = Route.Flights parameters
                        , refresh = Refresh.inOneHour

                        -- , flights = Loading
                        -- , originalFlights = Loading
                        , packageParameters = parameters
                        , departDate = parameters.departureDate
                        , returnDate = parameters.returnDate
                        , destination = parameters.destination |> Maybe.map .name |> Maybe.withDefault ""
                        , formItemDropdown =
                            [ Component.PriceFlight
                            , Component.Airlines
                            , Component.NumberOfStop
                            , Component.ArrivalDeparture
                            ]
                        , flightDestination =
                            model.flightDestination
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toAirportAndCity parameters |> SD.SetSelected)
                        , cities =
                            model.cities
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toCity parameters |> SD.SetSelected)
                    }

                ( flightModel, flightMsg ) =
                    Flight.initPage
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .flight
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        model.zone
                        parameters
                        model.flight
            in
            ( { baseModel | flight = flightModel }, Cmd.batch [ fromCmd, Cmd.map FlightMsg flightMsg, Card.Step.initComponent "flight" ] )

        Just (Route.Cars parameters) ->
            let
                baseModel =
                    { model
                        | currentRoute = Route.Cars parameters
                        , refresh = Refresh.inOneHour
                        , departDate = parameters.car.pickupDate
                        , returnDate = parameters.car.dropoffDate
                        , packageParameters = parameters
                        , destination = parameters.destination |> Maybe.map .name |> Maybe.withDefault ""
                        , formItemDropdown =
                            [ Component.PriceCar
                            , Component.PassengersCar
                            , Component.TypesCar
                            , Component.CompanysCar
                            ]
                        , cities =
                            model.cities
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toCity parameters |> SD.SetSelected)
                        , flightDestination = model.flightDestination |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput) |> SD.update (toAirportAndCity parameters |> SD.SetSelected)
                    }

                ( carModel, carMsg ) =
                    Car.initPage
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .car
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        (Car.initModel baseModel.today baseModel.config baseModel.packageParameters)
            in
            ( { baseModel | car = carModel }, Cmd.batch [ Cmd.map CarMsg carMsg, Card.Step.initComponent "car" ] )

        Just (Route.Events parameters) ->
            let
                baseModel =
                    { model
                        | currentRoute = Route.Events parameters
                        , refresh = Refresh.inOneHour
                        , departDate = parameters.event.minDate
                        , returnDate = parameters.event.maxDate
                        , packageParameters = parameters
                        , eventPage = 1
                        , destination = parameters.destination |> Maybe.map .name |> Maybe.withDefault ""
                        , formItemDropdown =
                            [ Component.PriceEvent
                            , Component.TypeEvent
                            , Component.SubtypeEvent
                            ]
                        , cities =
                            model.cities
                                |> SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput)
                                |> SD.update (toCity parameters |> SD.SetSelected)
                        , flightDestination = SD.update (parameters.destination |> Maybe.map .name |> Maybe.withDefault "" |> SD.SetInput) model.flightDestination
                    }

                ( eventModel, modelMsg ) =
                    Event.initPage
                        (model.appPrismic
                            |> RemoteData.toMaybe
                            |> Maybe.andThen .event
                            |> Maybe.map
                                (\s ->
                                    case s of
                                        DocumentLink t _ ->
                                            t.id

                                        _ ->
                                            ""
                                )
                        )
                        model.prismic
                        (Event.initModel baseModel.today baseModel.config baseModel.packageParameters)
            in
            ( { baseModel | event = eventModel }, Cmd.batch [ Cmd.map EventMsg modelMsg, Card.Step.initComponent "event" ] )

        Just (Route.Destination name) ->
            let
                ( destModel, destNewMsg ) =
                    Destination.initPage name model.prismic model.config model.destinationPage
            in
            ( { model | currentRoute = Route.Destination name, destinationPage = destModel }, Cmd.map DestinationMsg destNewMsg )

        Just (Route.RequestPackage promocode) ->
            ( { model | currentRoute = Route.RequestPackage promocode }
            , Nav.replaceUrl model.config.navkey
                (Route.toUrl (Route.RequestPackageFunnel (RequestPackage.Route.Start promocode)))
            )

        Just (Route.RequestPackageFunnel r) ->
            let
                ( pModel, _ ) =
                    RequestPackageFunnel.changeRoute r model.requestPackageFunnel
            in
            ( { model
                | currentRoute = Route.RequestPackageFunnel r
                , requestPackageFunnel = pModel
              }
            , if RequestPackage.Route.isStartRoute r then
                Nav.replaceUrl model.config.navkey
                    (Route.toUrl
                        (Route.RequestPackageFunnel
                            (RequestPackageFunnel.nextRoute
                                (RequestPackage.Route.Start (RequestPackageFunnel.extractQuery r))
                                (RequestPackageFunnel.orderedRoutes pModel |> RequestPackageFunnel.withStartPage (RequestPackageFunnel.extractQuery r))
                            )
                        )
                    )

              else
                Cmd.none
            )

        Just Route.Resorts ->
            ( { model | currentRoute = Route.Resorts }, MyPrismic.Destination.fetchAllRessort model.prismic HandleAllDestinations )

        Just Route.Destinations ->
            ( { model | currentRoute = Route.Destinations }, fetchAllDestinations model.prismic HandleAllDestinations )

        Just Route.FeatureEvents ->
            ( { model | currentRoute = Route.FeatureEvents }, fetchFeaturesEvents model.prismic HandleAllDestinations )

        Just (Route.EditPackage _) ->
            ( model, Cmd.none )

        Just Route.CreatePackage ->
            ( model, Cmd.none )

        Just Route.ThankYou ->
            ( { model | currentRoute = Route.ThankYou }, Cmd.none )

        Just (Route.MyPackage params) ->
            let
                cityCmd =
                    if Maybe.isNothing (SD.get model.flightDestination).selected then
                        fetchCityCmd model.config params

                    else
                        Cmd.none

                fromCmd =
                    case params.airportFrom of
                        Just { name } ->
                            getCityByIdAndNearestAirport model.config { id = "", name = name } HandleGetFlightFrom

                        _ ->
                            Cmd.none

                baseModel =
                    { model | currentRoute = Route.MyPackage params, refresh = Refresh.Never }
            in
            case params.destination of
                Just dest ->
                    let
                        depart =
                            params.departureDate
                                |> Maybe.withDefault (Date.add Date.Days 1 model.today)

                        arrival =
                            params.returnDate
                                |> Maybe.withDefault (Date.add Date.Days 4 model.today)
                    in
                    ( { baseModel
                        | departDate = Just depart
                        , returnDate = Just arrival
                        , packageParameters = params
                        , numberOfPassenger = params.numberOfAdult
                        , destination = dest.name
                        , cities =
                            model.cities
                                |> SD.update (dest.name |> SD.SetInput)
                                |> SD.update (toCity params |> SD.SetSelected)
                        , flightDestination =
                            model.flightDestination
                                |> SD.update (dest.name |> SD.SetInput)
                                |> SD.update (toAirportAndCity params |> SD.SetSelected)
                      }
                    , Cmd.batch [ cityCmd, fromCmd ]
                    )

                Nothing ->
                    ( baseModel, Cmd.batch [ cityCmd, fromCmd ] )

        -- check this
        Just Route.About ->
            ( { model | currentRoute = Route.About }, Cmd.none )

        Just Route.FAQ ->
            ( { model | currentRoute = Route.FAQ }, Cmd.none )

        Just Route.TermsAndConditions ->
            ( { model | currentRoute = Route.TermsAndConditions }, Cmd.none )

        Just Route.CookiesPolicies ->
            ( { model | currentRoute = Route.CookiesPolicies }, Cmd.none )

        Just Route.Policies ->
            ( { model | currentRoute = Route.Policies }, Cmd.none )

        Just Route.News ->
            ( { model | currentRoute = Route.News }, Cmd.none )

        Just Route.Blog ->
            ( { model | currentRoute = Route.Blog }, Nav.load (Builder.absolute [ "blogs" ] []) )

        Just (Route.Callback params) ->
            ( model
            , if String.isEmpty params.code && String.isEmpty params.state then
                Cmd.none

              else
                Cmd.batch
                    [ handleRedirectCallback ()
                    ]
            )

        Just (Route.Admin adminRoute) ->
            case model.config.authenticator.state of
                LoggedIn user ->
                    if user.profile.roles |> List.member "admin" then
                        let
                            ( adminModel, adminSub ) =
                                Admin.init adminRoute model.config model.packageParameters
                        in
                        ( { model | currentRoute = Route.Admin adminRoute, adminPage = adminModel }, Cmd.map ToAdminPage adminSub )

                    else
                        ( model, Nav.replaceUrl model.key "/" )

                LoggedOut ->
                    ( model, Nav.replaceUrl model.key "/" )

        Just Route.Profil ->
            case model.config.authenticator.state of
                LoggedIn _ ->
                    ( { model | currentRoute = Route.Profil, profilPage = Profil.init model.config }, Cmd.none )

                LoggedOut ->
                    ( model, Nav.replaceUrl model.key "/" )

        Just Route.Logout ->
            let
                ( _, cmd ) =
                    Authentication.update Authentication.LogOut model.config.authenticator
            in
            ( model, Cmd.batch [ Nav.replaceUrl model.key "/", Cmd.map AuthenticationMsg cmd, savePackageDropdown ( Encode.object [], False ) ] )

        Just Route.SignUp ->
            let
                ( m, c ) =
                    case model.config.authenticator.state of
                        LoggedIn _ ->
                            ( model.config.authenticator, Nav.replaceUrl model.key "/" )

                        LoggedOut ->
                            let
                                ( autModel, command ) =
                                    Authentication.update Authentication.ShowLogIn model.config.authenticator
                            in
                            ( autModel, Cmd.map AuthenticationMsg command )
            in
            ( { model | config = Config.setUser m model.config }, c )

        Just (Route.RedirectTravelPayout params) ->
            ( { model | currentRoute = Route.RedirectTravelPayout params }
            , Flight.getUrl model.config.domain params.searchId params.urlId (Flight.HandleFlightUrl >> FlightMsg)
            )

        Just (Route.Redirect params) ->
            ( { model | currentRoute = Route.Redirect params }
            , Nav.load params.url
            )

        Nothing ->
            ( model, Nav.load (url |> Url.toString) )


fetchCityCmd : Config.Config -> PackageP.Parameters -> Cmd Msg
fetchCityCmd config parameters =
    case parameters.airportTo of
        Just { name } ->
            getCityByIdAndNearestAirport config { id = "", name = name } HandleGetFlightDestination

        _ ->
            Cmd.none


toCity : Parameters -> Maybe City
toCity params =
    Maybe.map
        (\dest ->
            { name = dest.name
            , airportName = dest.name
            , id = Nothing
            , country = ""
            , countryCode = dest.countryCode
            , lat = dest.lat
            , long = dest.long
            , iata = ""
            }
        )
        params.destination


toAirportAndCity : Parameters -> Maybe CityWithNearestAirport
toAirportAndCity params =
    let
        airport =
            Maybe.map
                (\{ iata, lat, long, name, countryCode } ->
                    { code = iata
                    , location = ( lat, long )
                    , name = name
                    , countryCode = countryCode
                    , country = ""
                    }
                )
                params.airportTo

        city =
            Maybe.map
                (\dest ->
                    { name = dest.name
                    , airportName = ""
                    , id = Nothing
                    , country = ""
                    , countryCode = ""
                    , lat = dest.lat
                    , long = dest.long
                    , iata = ""
                    }
                )
                params.destination
    in
    Maybe.map2 (\a -> \c -> { airport = a, city = c }) airport city


notAskedTopDestinations : RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType ) -> Html Msg
notAskedTopDestinations topDestinations =
    regular
        { title = "Top Golf Destinations"
        , text_ = ""
        , modifier = "highlight"
        , content =
            destinationToCard topDestinations
        , showAll = Nothing
        }


destinationToCard : RemoteData Prismic.PrismicError ( Prismic.Model, Prismic.Response DestinationDocType ) -> List (Html Msg)
destinationToCard destinations =
    case destinations of
        Loading ->
            [ Loader.circleLoader ]

        NotAsked ->
            [ Loader.circleLoader ]

        Failure err ->
            case err of
                Prismic.DecodeDocumentError r ->
                    [ Html.text r ]

                _ ->
                    [ Html.text "ask mike" ]

        Success ( _, t ) ->
            t.results
                |> List.take 4
                |> List.map
                    (\c ->
                        Card.Tiny.tinyWithButton
                            { title = c.title |> Maybe.map Field.getTexts |> Maybe.withDefault (Field.getTexts c.city ++ ", " ++ Field.getTexts c.state)
                            , text_ = c.badgeContent
                            , modifier = ""
                            , modifierImage = ""
                            , image = c.mainImage.main.url
                            , requestUrl = Route.RequestPackageFunnel (RequestPackage.Route.Start Nothing) |> Route.toUrl |> Internal
                            , url =
                                -- c.externalLink
                                --     |> Maybe.map (.url >> External)
                                --     |> Maybe.withDefault
                                Route.Destination c.uuid
                                    |> Route.toUrl
                                    |> Internal
                            }
                    )


footer : Model -> Html msg
footer model =
    footer_
        { topDestinations =
            model.topDestinations
                |> RemoteData.map (\( _, t ) -> t.results |> List.map (\c -> { href_ = Route.Destination (Field.getTexts c.city |> String.toLower |> String.replace " " "_") |> Route.toUrl, name = Field.getTexts c.city ++ ", " ++ Field.getTexts c.state }))
                |> RemoteData.withDefault (HPC.cards |> List.map (\s -> { href_ = HPC.toParameters model.config.today s |> Route.Flights |> Route.toUrl, name = s.city }))
        , featureEvents =
            model.featureEvents
                |> RemoteData.map
                    (\( _, t ) ->
                        t.results
                            |> List.map
                                (\c ->
                                    { href_ =
                                        Route.Destination c.uuid
                                            |> Route.toUrl
                                    , name = c.title |> Maybe.map Field.getTexts |> Maybe.withDefault (Field.getTexts c.city ++ ", " ++ Field.getTexts c.state)
                                    }
                                )
                    )
                |> RemoteData.withDefault []
        }



-- VIEW


view : Model -> Browser.Document Msg
view model =
    { title = "MyBuddiesTrip"
    , body =
        Toast.toHtml model.toast CloseToast
            :: pageView model
            ++ (if True then
                    [ SignupModal.modal
                        { onInput = SetEmail
                        , onSubmit = SubmitEmail
                        , value = model.email
                        , onClose = CloseSignupModal
                        , error = model.modalInputError
                        }
                        model.isSignupModalOpen
                    ]

                else
                    []
               )
            ++ [ MyPackagePreviewModal.modal
                    { onClose = CloseMyPackagePreviewModal
                    , content =
                        case model.myPackageModalOpenOn of
                            Nothing ->
                                []

                            Just service ->
                                case service of
                                    "flight" ->
                                        myPackageFlight model

                                    "hotel" ->
                                        myPackageHotel model

                                    "event" ->
                                        myPackageEvent model

                                    "car" ->
                                        myPackageCar model

                                    "golf" ->
                                        myPackageGolf model

                                    "restaurant" ->
                                        myPackageRestaurant model

                                    _ ->
                                        []
                    }
                    (model.myPackageModalOpenOn /= Nothing)
               ]
    }


with : List (Html Msg) -> List (Html Msg) -> List (Html Msg)
with parts1 parts2 =
    parts2 ++ parts1


navSteps : Model -> List (Step Msg)
navSteps model =
    [ { image = ""
      , service = "flight"
      , title = "Flights"
      , text_ = "flight added"
      , logo = ""
      , price = ""
      , icon = "flight"
      , number = model.myPackage |> Maybe.map (.flight >> Dict.size) |> Maybe.withDefault 0
      , route = Route.Flights model.packageParameters
      , packageParameters = model.packageParameters
      , modifier =
            model.packageParameters.package.includes
                |> Maybe.map (List.map fromString)
                |> Maybe.map
                    (\s ->
                        if s |> List.any (Maybe.map isFlightService >> Maybe.withDefault False) then
                            ""

                        else
                            "disabled"
                    )
                |> Maybe.withDefault "disabled"
      , jsClass =
            if Route.isFlightsRoute model.currentRoute then
                "js-current"

            else
                ""
      , onPreviewClick = OpenMyPackagePreviewModal "flight"
      }
    , { image = ""
      , service = "stay"
      , title = "Stays"
      , text_ = "stay added"
      , logo = ""
      , price = ""
      , icon = "hotel"
      , number = model.myPackage |> Maybe.map (.hotel >> Dict.size) |> Maybe.withDefault 0
      , route = Route.Hotels model.packageParameters
      , packageParameters = model.packageParameters
      , modifier =
            model.packageParameters.package.includes
                |> Maybe.map (List.map fromString)
                |> Maybe.map
                    (\s ->
                        if s |> List.any (Maybe.map isStayService >> Maybe.withDefault False) then
                            ""

                        else
                            "disabled"
                    )
                |> Maybe.withDefault "disabled"
      , jsClass =
            if Route.isHotelsRoute model.currentRoute then
                "js-current"

            else
                ""
      , onPreviewClick = OpenMyPackagePreviewModal "hotel"
      }
    , { image = ""
      , service = "car"
      , title = "Cars"
      , text_ = "car added"
      , logo = ""
      , price = ""
      , icon = "car"
      , number = model.myPackage |> Maybe.map (.car >> Dict.size) |> Maybe.withDefault 0
      , route = Route.Cars model.packageParameters
      , packageParameters = model.packageParameters
      , modifier =
            model.packageParameters.package.includes
                |> Maybe.map (List.map fromString)
                |> Maybe.map
                    (\s ->
                        if s |> List.any (Maybe.map isCarService >> Maybe.withDefault False) then
                            ""

                        else
                            "disabled"
                    )
                |> Maybe.withDefault "disabled"
      , jsClass =
            if Route.isCarsRoute model.currentRoute then
                "js-current"

            else
                ""
      , onPreviewClick = OpenMyPackagePreviewModal "car"
      }
    , { image = ""
      , service = "tee time"
      , title = "Tee Times"
      , text_ = "tee times added"
      , logo = ""
      , price = ""
      , icon = "golf"
      , number = model.myPackage |> Maybe.map (.golf >> Dict.size) |> Maybe.withDefault 0
      , route = Route.Golf model.packageParameters
      , packageParameters = model.packageParameters
      , modifier =
            model.packageParameters.package.includes
                |> Maybe.map (List.map fromString)
                |> Maybe.map
                    (\s ->
                        if s |> List.any (Maybe.map isTeeTimeService >> Maybe.withDefault False) then
                            ""

                        else
                            "disabled"
                    )
                |> Maybe.withDefault "disabled"
      , jsClass =
            if Route.isGolfRoute model.currentRoute then
                "js-current"

            else
                ""
      , onPreviewClick = OpenMyPackagePreviewModal "golf"
      }
    , { image = ""
      , service = "event"
      , title = "Events"
      , text_ = "event added"
      , logo = ""
      , price = ""
      , icon = "event"
      , number = model.myPackage |> Maybe.map (.event >> Dict.size) |> Maybe.withDefault 0
      , route = Route.Events model.packageParameters
      , packageParameters = model.packageParameters
      , modifier =
            model.packageParameters.package.includes
                |> Maybe.map (List.map fromString)
                |> Maybe.map
                    (\s ->
                        if s |> List.any (Maybe.map isEventService >> Maybe.withDefault False) then
                            ""

                        else
                            "disabled"
                    )
                |> Maybe.withDefault "disabled"
      , jsClass =
            if Route.isEventsRoute model.currentRoute then
                "js-current"

            else
                ""
      , onPreviewClick = OpenMyPackagePreviewModal "event"
      }
    , { image = ""
      , service = "Restaurant"
      , title = "Restaurants"
      , text_ = "restaurant added"
      , logo = ""
      , price = ""
      , icon = "restaurant"
      , number = model.myPackage |> Maybe.map (.restaurant >> Dict.size) |> Maybe.withDefault 0
      , route = Route.Restaurant model.packageParameters
      , packageParameters = model.packageParameters
      , modifier =
            model.packageParameters.package.includes
                |> Maybe.map (List.map fromString)
                |> Maybe.map
                    (\s ->
                        if s |> List.any (Maybe.map isRestaurantService >> Maybe.withDefault False) then
                            ""

                        else
                            "disabled"
                    )
                |> Maybe.withDefault "disabled"
      , jsClass =
            if Route.isRestaurantRoute model.currentRoute then
                "js-current"

            else
                ""
      , onPreviewClick = OpenMyPackagePreviewModal "restaurant"
      }
    ]


nextStep : Route.Route -> List (Step Msg) -> Maybe String
nextStep cRoute allSteps =
    allSteps |> List.next (\a -> (a.route |> Route.toServiceName) == (cRoute |> Route.toServiceName)) |> Maybe.map (.route >> Route.toUrl)


previousStep : Route.Route -> List (Step Msg) -> Maybe String
previousStep cRoute allSteps =
    allSteps |> List.reverse |> List.next (\a -> (a.route |> Route.toServiceName) == (cRoute |> Route.toServiceName)) |> Maybe.map (.route >> Route.toUrl)


steps : Model -> Html Msg
steps model =
    let
        allSteps =
            navSteps model
    in
    div [ class "mbt-section mbt-section--highlight-02" ]
        [ div [ class "mbt-container" ]
            [ div [ class "mbt-section__step" ]
                [ div [ class "mbt-section__step-nav" ]
                    [ a [ class "btn btn--primary btn--sm btn--rounded", href (previousStep model.currentRoute allSteps |> Maybe.withDefault "#") ]
                        [ i [ class "icon icon--arrow-ios-back-outline" ]
                            []
                        ]
                    ]
                , allSteps
                    |> List.map (\s -> s |> step >> List.singleton >> li [ class "", id s.service ])
                    |> ul []
                , div [ class "mbt-section__step-nav" ]
                    [ a [ class "btn btn--primary btn--sm btn--rounded", href (nextStep model.currentRoute allSteps |> Maybe.withDefault "#") ]
                        [ i [ class "icon icon--arrow-ios-forward-outline" ]
                            []
                        ]
                    ]
                ]
            ]
        ]


mbtHeader : Model -> Html Msg
mbtHeader model =
    Header.Header.header
        { modifier = ""
        , changeCurrency = SelectCurrency
        , selectedCurrency = model.config.currency
        , logo = model.appPrismic |> RemoteData.toMaybe |> Maybe.map (\s -> s.logo.main.url) |> Maybe.withDefault "./img/logo-mybuddiestrip-dark.svg"
        , logoMobile = model.appPrismic |> RemoteData.toMaybe |> Maybe.map (\s -> s.logoMobile.main.url) |> Maybe.withDefault "./img/logo-mybuddiestrip-icon.svg"
        , onDropdownClick = HandleDropdownClick
        , parameters = model.packageParameters
        , myPackageItems = myPackageDropdownItems model
        , authentification = model.config.authenticator
        , loggin = AuthenticationMsg Authentication.ShowLogIn
        , logout = AuthenticationMsg Authentication.LogOut
        , onSubscribeClick = ToggleSignupModal
        }


pageView : Model -> List (Html Msg)
pageView model =
    let
        { viewport } =
            model.config
    in
    [ div [ Attrs.id "div-for-header", Attrs.attribute "aria-hidden" "true" ] []
    , mbtHeader model
    ]
        ++ (case model.currentRoute of
                Route.Home _ ->
                    (Home.view model.home model.featureEvents |> List.map (Html.map HomeMsg))
                        ++ [ footer model ]

                Route.ThankYou ->
                    [ section [ class "h-screen flex flex-col justify-center items-center" ]
                        [ h1 [ class "text-xxl text-sky-400 font-bold" ] [ text "Thank you for choosing MyBuddiesTrip" ]
                        , p [ class "text-l m-2" ] [ text "We look forward to helping you plan an amazing golfing experience" ]
                        , a [ class "btn btn--md btn--action", href "https://ca.trustpilot.com/review/mybuddiestrip.com", Attrs.target "_blank" ] [ text "Leave A Review" ]

                        -- , button [] []
                        -- <!-- TrustBox widget - Review Collector -->
                        -- <div class="trustpilot-widget" data-locale="en-CA" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6086d070bf5a2000019e71f9" data-style-height="52px" data-style-width="100%">
                        -- <a href="https://ca.trustpilot.com/review/mybuddiestrip.com" target="_blank" rel="noopener">Trustpilot</a>
                        -- </div>
                        -- <!-- End TrustBox widget -->
                        ]
                    , footer model
                    ]

                Route.CreatePackage ->
                    [ text "Not Implemented Yet"
                    , footer model
                    ]

                Route.EditPackage _ ->
                    [ text "Not Implemented Yet"
                    , footer model
                    ]

                Route.MyPackage _ ->
                    []
                        |> with
                            (myPackagePage model)
                        |> with
                            [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Flights _ ->
                    (if (viewport |> Maybe.map (.scene >> .width) |> Maybe.withDefault 0) <= 764 then
                        CallToAction.viewMobile CallToAction.Hotel (model.hotel.hotels |> RemoteData.Extra.isFetched) model.hotel.callToAction |> Html.map (CallToActionMsg >> HomeMsg)

                     else
                        text ""
                    )
                        :: flightPage
                            model
                        ++ [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Hotels _ ->
                    (if (viewport |> Maybe.map (.scene >> .width) |> Maybe.withDefault 0) <= 764 then
                        CallToAction.viewMobile CallToAction.Hotel (model.hotel.hotels |> RemoteData.Extra.isFetched) model.hotel.callToAction |> Html.map (CallToActionMsg >> HomeMsg)

                     else
                        text ""
                    )
                        :: hotelPage model
                        ++ [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Cars _ ->
                    (if (viewport |> Maybe.map (.scene >> .width) |> Maybe.withDefault 0) <= 764 then
                        CallToAction.viewMobile CallToAction.Car (model.car.cars |> RemoteData.Extra.isFetched) model.car.callToAction |> Html.map (CallToActionMsg >> HomeMsg)

                     else
                        text ""
                    )
                        :: carPage model
                        ++ [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Golf _ ->
                    (if (viewport |> Maybe.map (.scene >> .width) |> Maybe.withDefault 0) <= 764 then
                        CallToAction.viewMobile CallToAction.Golf (model.golf.clubs |> RemoteData.Extra.isFetched) model.golf.callToAction |> Html.map (CallToActionMsg >> HomeMsg)

                     else
                        text ""
                    )
                        :: golfPage model
                        ++ [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Events _ ->
                    (if (viewport |> Maybe.map (.scene >> .width) |> Maybe.withDefault 0) <= 764 then
                        CallToAction.viewMobile CallToAction.Event (model.event.events |> RemoteData.Extra.isFetched) model.event.callToAction |> Html.map (CallToActionMsg >> HomeMsg)

                     else
                        text ""
                    )
                        :: eventPage model
                        ++ [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Restaurant _ ->
                    (if (viewport |> Maybe.map (.scene >> .width) |> Maybe.withDefault 0) <= 764 then
                        CallToAction.viewMobile CallToAction.Restaurant (model.restaurant.restaurants |> RemoteData.Extra.isFetched) model.restaurant.callToAction |> Html.map (CallToActionMsg >> HomeMsg)

                     else
                        text ""
                    )
                        :: restaurantPage model
                        ++ [ SignupModal.banner ToggleSignupModal, footer model ]

                Route.Resorts ->
                    [ Resort.view "Top Golf Resorts" model.allDestinations
                    , footer model
                    ]

                Route.Destinations ->
                    [ DestinationsPage.view "Top Golf Destinations" model.allDestinations
                    , footer model
                    ]

                Route.FeatureEvents ->
                    [ Resort.view "Featured Events" model.allDestinations
                    , footer model
                    ]

                Route.Destination _ ->
                    [ Destination.view model.destinationPage, SignupModal.banner ToggleSignupModal, footer model ]

                Route.RequestPackage _ ->
                    []

                Route.RequestPackageFunnel r ->
                    RequestPackageFunnel.view r model.requestPackageFunnel |> List.map (Html.map ToRequestPackageFunnel)

                Route.About ->
                    [ section [ class "section animate-slow" ]
                        [ div [ class "container" ]
                            About.page
                        ]
                    , SignupModal.banner ToggleSignupModal
                    , footer model
                    ]

                Route.FAQ ->
                    [ div [ class "container container--content grid" ]
                        [ div [ class "content content--center" ] FAQ.toHtml
                        ]
                    , SignupModal.banner ToggleSignupModal
                    , footer model
                    ]

                Route.TermsAndConditions ->
                    [ div [ class "container container--content grid" ]
                        [ div [ class "content content--center" ] TermsAndConditions.toHtml
                        ]
                    , SignupModal.banner ToggleSignupModal
                    , footer model
                    ]

                Route.CookiesPolicies ->
                    [ div [ class "container container--content grid" ]
                        [ div [ class "content content--center" ] CookiesPolicies.toHtml
                        ]
                    , SignupModal.banner ToggleSignupModal
                    , footer model
                    ]

                Route.Policies ->
                    [ div [ class "container container--content grid" ]
                        [ div [ class "content content--center" ] Policies.toHtml
                        ]
                    , SignupModal.banner ToggleSignupModal
                    , footer model
                    ]

                Route.News ->
                    [ div [ class "container container--content grid" ] [ div [ class "content content--center" ] MBTNews.toHtml ] ]

                Route.Blog ->
                    []

                Route.Admin _ ->
                    [ Admin.view model.adminPage |> Html.map ToAdminPage
                    ]

                Route.Profil ->
                    [ Profil.view model.profilPage |> Html.map ToProfilPage
                    ]

                Route.RedirectTravelPayout _ ->
                    []

                Route.Redirect _ ->
                    []

                Route.Logout ->
                    []

                Route.SignUp ->
                    []

                Route.Callback _ ->
                    []
           )



-- HPC.cards
--     |> List.map
--         (\s ->
--             { city =
--                 { name = s.info.name |> Maybe.withDefault ""
--                 , city = s.info.city |> Maybe.withDefault ""
--                 , cityId = s.info.cityId |> Maybe.withDefault ""
--                 , country = s.info.country |> Maybe.withDefault ""
--                 , countryCode = s.info.countryCode |> Maybe.withDefault ""
--                 , lat = s.info.lat |> Maybe.withDefault 0
--                 , long = s.info.long |> Maybe.withDefault 0
--                 , airports =
--                     [ { code = s.info.iata |> Maybe.withDefault ""
--                       , location = ( s.info.lat |> Maybe.withDefault 0, s.info.long |> Maybe.withDefault 0 )
--                       , name = s.info.name |> Maybe.withDefault ""
--                       , countryCode = s.info.countryCode |> Maybe.withDefault ""
--                       , country = s.info.country |> Maybe.withDefault ""
--                       }
--                     ]
--                 , iata = s.info.iata |> Maybe.map List.singleton |> Maybe.withDefault []
--                 }
--             , airport =
--                 { code = s.info.iata |> Maybe.withDefault ""
--                 , location = ( s.info.lat |> Maybe.withDefault 0, s.info.long |> Maybe.withDefault 0 )
--                 , name = s.info.name |> Maybe.withDefault ""
--                 , countryCode = s.info.countryCode |> Maybe.withDefault ""
--                 , country = s.info.country |> Maybe.withDefault ""
--                 }
--             }
--         )


myPackageDropdownItems : Model -> List Header.Header.MyPackageDropdownItem
myPackageDropdownItems model =
    case model.myPackage of
        Nothing ->
            []

        Just myPackage ->
            let
                numberOfPersons =
                    PackageP.numberOfCashablePerson model.packageParameters
            in
            (myPackage.flight
                |> Dict.toList
                |> List.filterMap
                    (\( _, data ) ->
                        case data.item of
                            Success flight ->
                                let
                                    routes =
                                        flight.routesToGo |> List.sortBy (\s -> Time.posixToMillis s.departureTime)

                                    logo =
                                        routes |> List.head |> Maybe.map .logo |> Maybe.withDefault ""

                                    dates =
                                        formatDateRange model.zone flight.departureDate flight.returnDate
                                in
                                Just
                                    { service = "Flight"
                                    , image = logo
                                    , bookUrl = Route.toUrl <| Route.Redirect <| Route.RedirectParams "flight" flight.url
                                    , price = Just flight.price
                                    , dates = dates
                                    }

                            _ ->
                                Nothing
                    )
            )
                ++ (myPackage.hotel
                        |> Dict.toList
                        |> List.filterMap
                            (\( _, data ) ->
                                case data.item of
                                    Success hotel ->
                                        Just
                                            { service = "Hotel"
                                            , image = List.head hotel.images |> Maybe.withDefault "./img/no_image.svg"
                                            , bookUrl = hotel.room.link
                                            , price = Just hotel.room.total
                                            , dates = formatDateRange model.zone hotel.checkin hotel.checkout
                                            }

                                    _ ->
                                        Nothing
                            )
                   )
                ++ (myPackage.event
                        |> Dict.toList
                        |> List.filterMap
                            (\( _, data ) ->
                                case data.item of
                                    Success event ->
                                        Just
                                            { service = "Event"
                                            , image = Event.image event |> Maybe.withDefault "./img/no_image.svg"
                                            , bookUrl = Route.toUrl <| Route.Redirect <| Route.RedirectParams "event" <| Event.url event
                                            , price = Event.price event
                                            , dates = ""
                                            }

                                    _ ->
                                        Nothing
                            )
                   )
                ++ (myPackage.car
                        |> Dict.toList
                        |> List.filterMap
                            (\( _, data ) ->
                                case data.item of
                                    Success car ->
                                        Just
                                            { service = "Car"
                                            , image = car.car.image
                                            , bookUrl = Route.toUrl <| Route.Redirect <| Route.RedirectParams "car" car.link
                                            , price = Just car.price.totalPrice
                                            , dates = formatDateRange model.zone car.pickupDateTime car.dropoffDateTime
                                            }

                                    _ ->
                                        Nothing
                            )
                   )
                ++ (myPackage.golf
                        |> Dict.toList
                        |> List.filterMap
                            (\( _, data ) ->
                                case data.item of
                                    Success golf ->
                                        Just
                                            { service = "Golf"
                                            , image = golf.image
                                            , bookUrl = Route.toUrl <| Route.Redirect <| Route.RedirectParams "golf" golf.link
                                            , price = Just (golf.price * Basics.toFloat numberOfPersons)
                                            , dates = ""
                                            }

                                    _ ->
                                        Nothing
                            )
                   )
                ++ (myPackage.restaurant
                        |> Dict.toList
                        |> List.filterMap (restaurantPackage numberOfPersons)
                   )


restaurantPackage : Int -> ( String, MyPackage.Item Restaurant.RestaurantType ) -> Maybe Header.Header.MyPackageDropdownItem
restaurantPackage numberOfPersons ( _, data ) =
    case data.item of
        Success restaurant ->
            Just
                { service = "Restaurant"
                , image = restaurant.imageUrl |> Maybe.withDefault ""
                , bookUrl = restaurant.reserveUrl
                , price = Just ((Maybe.map Basics.toFloat restaurant.price |> Maybe.withDefault 0) * Basics.toFloat numberOfPersons)
                , dates = restaurant.name
                }

        _ ->
            Nothing


formatDateRange : Time.Zone -> Maybe Time.Posix -> Maybe Time.Posix -> String
formatDateRange zone maybeFrom maybeTo =
    case maybeFrom of
        Nothing ->
            ""

        Just timeFrom ->
            case maybeTo of
                Nothing ->
                    ""

                Just timeTo ->
                    let
                        formatWithoutYear =
                            "MMM dd"

                        formatWithYear =
                            "MMM dd yyyy"

                        from =
                            Date.fromPosix zone timeFrom

                        to =
                            Date.fromPosix zone timeTo

                        fromFormat =
                            if Date.year from == Date.year to then
                                formatWithoutYear

                            else
                                formatWithYear

                        toFormat =
                            formatWithYear
                    in
                    Date.format fromFormat from ++ " – " ++ Date.format toFormat to


myPackageFlight : Model -> List (Html Msg)
myPackageFlight model =
    let
        flight =
            model.myPackage |> Maybe.map (.flight >> Dict.toList) |> Maybe.withDefault []
    in
    myPackageCard
        model.config.currentTime
        { name = "Flights"
        , icon = "flight"
        , url = Route.Flights model.packageParameters |> Route.toUrl
        , description = "Need a flight? It looks like you don't have one"
        , description2 = "Lowest fares and lots of options"
        , outDatedInterval = Time.Minute
        , outDatedValue = 30
        , moreOptionUrl = Route.Flights model.packageParameters |> Route.toUrl
        , onRemove = RemoveFlight
        , content =
            \outdate ->
                \f ->
                    Flight.card True
                        (Route.toServiceName <| Route.MyPackage PackageP.defaultParameters)
                        outdate
                        (PackageP.numberOfCashablePerson model.packageParameters)
                        model.config.currency
                        (\_ ->
                            \_ -> text ""
                         -- Button.button
                         --     { modifier = "btn--primary btn--sm"
                         --     , actions = [ href <| Route.toUrl <| Route.Redirect <| Route.RedirectParams (Route.toServiceName <| Route.MyPackage PackageP.defaultParameters) f.url, Attrs.target "_blank", Attrs.classList [ ( "btn--disabled", outdate ) ] ]
                         --     , label = "Learn More"
                         --     }
                        )
                        model.zone
                        AddSelectFlight
                        f
        }
        flight


myPackageEvent : Model -> List (Html Msg)
myPackageEvent model =
    let
        event =
            model.myPackage |> Maybe.map (.event >> Dict.toList) |> Maybe.withDefault []
    in
    myPackageCard
        model.config.currentTime
        { name = "Events"
        , icon = "event"
        , url = Route.Events model.packageParameters |> Route.toUrl
        , description = "Need tickets to an event? It looks like you don't have any"
        , description2 = "From sports to shows"
        , outDatedInterval = Time.Day
        , outDatedValue = 1
        , moreOptionUrl = Route.Events model.packageParameters |> Route.toUrl
        , onRemove = RemoveEvent
        , content =
            \outdate ->
                Event.card True
                    outdate
                    (PackageP.numberOfCashablePerson model.packageParameters)
                    model.config.currency
                    model.priceMode
                    (\_ -> \_ -> text "")
                    AddSelectedEvent
                    (model.event.viatorEventTypes ++ model.event.viatorEventSubTypes)
                    (model.event.seatGeekEventTypes |> RemoteData.withDefault [])

        -- >> myPackageCard2 outdate
        }
        event


myPackageHotel : Model -> List (Html Msg)
myPackageHotel model =
    let
        hotel =
            model.myPackage |> Maybe.map (.hotel >> Dict.toList) |> Maybe.withDefault []
    in
    myPackageCard
        model.config.currentTime
        { name = "Stays"
        , icon = "hotel"
        , description = "Need a place to stay? It looks like you don't have one"
        , url = Route.Hotels model.packageParameters |> Route.toUrl
        , description2 = "Your Schedule. Your Comfort"
        , outDatedInterval = Time.Hour
        , outDatedValue = 2
        , moreOptionUrl = Route.Hotels model.packageParameters |> Route.toUrl
        , onRemove = RemoveHotel
        , content =
            \isOutdated ->
                HotelView.card True model.config.currency isOutdated (addToPackageAction model) AddSelectedHotel
        }
        hotel


myPackageCar : Model -> List (Html Msg)
myPackageCar model =
    let
        car =
            model.myPackage |> Maybe.map (.car >> Dict.toList) |> Maybe.withDefault []
    in
    myPackageCard
        model.config.currentTime
        { name = "Cars"
        , icon = "car"
        , url = Route.Cars model.packageParameters |> Route.toUrl
        , description = "Need a vehicle to get around? It looks like you don't have one"
        , description2 = "All type of cars"
        , outDatedInterval = Time.Day
        , outDatedValue = 1
        , moreOptionUrl = Route.Cars model.packageParameters |> Route.toUrl
        , onRemove = RemoveCar
        , content =
            \isOutdated ->
                Car.card model.config.currency isOutdated ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters ) (\_ -> \_ -> text "") AddSelectedCar
        }
        car


myPackageGolf : Model -> List (Html Msg)
myPackageGolf model =
    let
        golf =
            model.myPackage |> Maybe.map (.golf >> Dict.toList) |> Maybe.withDefault []
    in
    myPackageCard
        model.config.currentTime
        { name = "Tee Times"
        , icon = "golf"
        , url = Route.Golf model.packageParameters |> Route.toUrl
        , description = "Need a tee time? Great memories start at great golf courses"
        , description2 = "Swing your swing"
        , outDatedInterval = Time.Day
        , outDatedValue = 1
        , moreOptionUrl = Route.Golf model.packageParameters |> Route.toUrl
        , onRemove = RemoveGolf
        , content =
            \isOutdated ->
                Golf.card model.config.currency isOutdated (PackageP.numberOfCashablePerson model.packageParameters) model.priceMode (\_ -> \_ -> text "") AddSelectedGolf
        }
        golf


myPackageRestaurant : Model -> List (Html Msg)
myPackageRestaurant model =
    let
        restaurant =
            model.myPackage |> Maybe.map (.restaurant >> Dict.toList) |> Maybe.withDefault []
    in
    myPackageCard
        model.config.currentTime
        { name = "Restaurants"
        , icon = "restaurant"
        , url = Route.Restaurant model.packageParameters |> Route.toUrl
        , description = "Need a table to go eat? It looks like you don't have one"
        , description2 = "Fuel up post round"
        , outDatedInterval = Time.Day
        , outDatedValue = 1
        , moreOptionUrl = Route.Restaurant model.packageParameters |> Route.toUrl
        , onRemove = RemoveRestaurant
        , content =
            \_ ->
                Restaurant.restaurantCard
                    True
                    False
                    (PackageP.numberOfCashablePerson model.packageParameters)
                    model.config.currency
                    model.priceMode
                    (\_ -> \_ -> text "")
                    AddSelectedRestaurant

        -- >> myPackageCard2 outdate
        }
        restaurant


myPackagePage : Model -> List (Html Msg)
myPackagePage model =
    let
        { flight, hotel, event, car, restaurant, golf } =
            model.myPackage
                |> Maybe.map (\s -> { flight = s.flight, hotel = s.hotel, event = s.event, car = s.car, restaurant = s.restaurant, golf = s.golf })
                |> Maybe.withDefault { flight = Dict.empty, hotel = Dict.empty, event = Dict.empty, car = Dict.empty, restaurant = Dict.empty, golf = Dict.empty }

        oneIsOutdated =
            (flight |> Dict.values |> List.any (\data -> Time.diff Time.Hour Time.utc data.addedOn model.config.currentTime > 1))
                || (hotel |> Dict.values |> List.any (\data -> Time.diff Time.Hour Time.utc data.addedOn model.config.currentTime > 2))
                || (event |> Dict.values |> List.any (\data -> Time.diff Time.Day Time.utc data.addedOn model.config.currentTime > 1))
                || (car |> Dict.values |> List.any (\data -> Time.diff Time.Day Time.utc data.addedOn model.config.currentTime > 1))
                || (restaurant |> Dict.values |> List.any (\data -> Time.diff Time.Day Time.utc data.addedOn model.config.currentTime > 1))
                || (golf |> Dict.values |> List.any (\data -> Time.diff Time.Day Time.utc data.addedOn model.config.currentTime > 1))

        -- (model.loadingMore |> Dict.isEmpty |> not)
        --     || RemoteData.isLoading model.hotels
        --     || RemoteData.isLoading model.restaurants
        --     || RemoteData.isLoading model.flights
        --     || RemoteData.isLoading model.cars
        --     || RemoteData.isLoading model.events
    in
    [ Container.container [ Container.Content ] div [ class "grid grid--between grid--header-spacing" ] <|
        [ div [ class "filter filter-details" ]
            [ ButtonIcon.buttonIcon
                { modifier = "btn--primary btn--full btn--md btn--icon-left filter"
                , icon = "menu-2-outline"
                , action = onClick ToggleDetail
                , buttonText = "Details"
                }
            ]
        , Content.content div [ class "animate-slow" ] <|
            [ Loader.alert { message = "Your search results have expired. Please update your search criteria to get off the bogey train", onClose = NoOp, isShowed = oneIsOutdated }
            , Content.header Html.header [] <|
                [ div [ class "content__group-title content__group-title--edit" ]
                    [ if model.isEditableTitleActivated then
                        input [ Attrs.id "package-title-rename", onInput SetTitle, onBlur ToggleTitleEditableMode, Attrs.autofocus True ] [ model.myPackage |> Maybe.map .name |> Maybe.withDefault "" |> text ]

                      else
                        h1 [ class "content__title" ]
                            [ if model.myPackage |> Maybe.map (.name >> String.isEmpty) |> Maybe.withDefault True then
                                "Your Package" |> text

                              else
                                model.myPackage |> Maybe.map .name |> Maybe.withDefault "" |> text
                            ]
                    , a [ href "#", onClick ToggleTitleEditableMode ] [ i [ class "icon icon--edit-outline" ] [] ]
                    ]
                ]
            , ul [ class "timeline" ] <|
                let
                    flightHtml =
                        myPackageFlight model

                    hotelHtml =
                        myPackageHotel model

                    eventHtml =
                        myPackageEvent model

                    carHtml =
                        myPackageCar model

                    golfHtml =
                        myPackageGolf model

                    restaurantHtml =
                        myPackageRestaurant model
                in
                flightHtml ++ hotelHtml ++ carHtml ++ golfHtml ++ eventHtml ++ restaurantHtml
            ]
        , Html.aside [ class "sidebar sidebar--detail animate-standard", classList [ ( "js-active", model.openDetail ) ] ] <|
            let
                expended =
                    [ hotel
                        |> Dict.values
                        |> List.map (\c -> c.item |> RemoteData.map (\s -> s.room.total |> Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )) |> RemoteData.withDefault 0)
                        |> List.foldl (+) 0
                    , flight
                        |> Dict.values
                        |> List.map (\c -> c.item |> RemoteData.map (\s -> s.price |> Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )) |> RemoteData.withDefault 0)
                        |> List.foldl (+) 0
                    , car
                        |> Dict.values
                        |> List.map (\c -> c.item |> RemoteData.map (\s -> s.price.totalPrice |> Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )) |> RemoteData.withDefault 0)
                        |> List.sum
                    , golf
                        |> Dict.values
                        |> List.map
                            (\c ->
                                c.item
                                    |> RemoteData.map
                                        (\s ->
                                            s.price
                                                |> Money.calculateWithPricePerBuddy ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                        )
                                    |> RemoteData.withDefault 0
                            )
                        |> List.sum
                    , event
                        |> Dict.values
                        |> List.map
                            (\c ->
                                c.item
                                    |> RemoteData.map
                                        (\s ->
                                            Event.price s
                                                |> Maybe.withDefault 0
                                                |> Money.calculateWithPricePerBuddy ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                        )
                                    |> RemoteData.withDefault 0
                            )
                        |> List.sum
                    , restaurant
                        |> Dict.values
                        |> List.map
                            (\c ->
                                c.item
                                    |> RemoteData.map (\s -> s.price |> Maybe.withDefault 0 |> Basics.toFloat |> Money.calculateWithPricePerBuddy ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters ))
                                    |> RemoteData.withDefault 0
                            )
                        |> List.foldl (+) 0
                    ]
                        |> List.sum
            in
            [ div [ class "sidebar__close" ]
                [ span [ class "weight-medium margin-right-xs", onClick ToggleDetail ] [ text "Close" ]
                , ButtonRounded.buttonRounded { modifier = "btn--light btn--sm btn--rounded", icon = "close-outline", action = onClick ToggleDetail, openInNewOnglet = False }
                ]
            , Sidebar.content ul
                []
                [ Sidebar.item li [] <|
                    [ UIList.list ul [ class "box box--light box--small" ] <|
                        [ text "Date"
                        ]
                    ]
                ]
            , Sidebar.content ul [] <|
                [ Sidebar.item li [] <|
                    [ UIList.list ul [ class "box box--light box--small" ] <|
                        [ case flight |> Dict.values of
                            [] ->
                                text ""

                            flights ->
                                MyPackage.toPrice
                                    { price =
                                        flights
                                            |> List.map
                                                (\f ->
                                                    f.item
                                                        |> RemoteData.map
                                                            (\c ->
                                                                c.price
                                                                    |> Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                                            )
                                                        |> RemoteData.withDefault 0
                                                )
                                            |> List.foldl (+) 0
                                    , currency = model.config.currency
                                    , names =
                                        flights
                                            |> List.map
                                                (\s ->
                                                    s.item
                                                        |> RemoteData.map
                                                            (\f ->
                                                                f.routesToGo
                                                                    -- ++ s.routesToReturn
                                                                    |> List.sortBy (\t -> Time.posixToMillis t.departureTime)
                                                                    |> Flight.toAirportStop
                                                                    |> List.firstAndLast
                                                                    |> Maybe.map
                                                                        (\( a, b ) -> a.name ++ " - " ++ b.name)
                                                                    |> Maybe.withDefault ""
                                                            )
                                                        |> RemoteData.withDefault ""
                                                 -- |> List.foldr (\curr -> \new -> curr.name :: new) []
                                                 -- |> String.join " - "
                                                )
                                    , title = "Flight"
                                    , icon = "icon icon--flight"
                                    }
                        , case hotel |> Dict.values of
                            [] ->
                                text ""

                            hotels ->
                                MyPackage.toPrice
                                    { price =
                                        hotels
                                            |> List.map (\h -> h.item |> RemoteData.map (\t -> t.room.total |> Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )) |> RemoteData.withDefault 0)
                                            |> List.foldl (+) 0
                                    , names = hotels |> List.map (\s -> s.item |> RemoteData.map (\t -> t.name) |> RemoteData.withDefault "")
                                    , title = "Stay"
                                    , icon = "icon icon--hotel"
                                    , currency = model.config.currency
                                    }
                        , case car |> Dict.values of
                            [] ->
                                text ""

                            cars ->
                                MyPackage.toPrice
                                    { price =
                                        cars
                                            |> List.map (\c -> c.item |> RemoteData.map (\s -> s.price.totalPrice |> Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )) |> RemoteData.withDefault 0)
                                            |> List.foldl (+) 0
                                    , names = cars |> List.map (\s -> s.item |> RemoteData.map (\c -> c.car.name) |> RemoteData.withDefault "")
                                    , title = "Car"
                                    , icon = "icon icon--car"
                                    , currency = model.config.currency
                                    }
                        , case golf |> Dict.values of
                            [] ->
                                text ""

                            golfs ->
                                MyPackage.toPrice
                                    { price =
                                        golfs
                                            |> List.map
                                                (\c ->
                                                    c.item
                                                        |> RemoteData.map
                                                            (\s ->
                                                                s.price
                                                                    |> Money.calculateWithPricePerBuddy ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                                            )
                                                        |> RemoteData.withDefault 0
                                                )
                                            |> List.foldl (+) 0
                                    , names = golfs |> List.map (\s -> s.item |> RemoteData.map .name |> RemoteData.withDefault "")
                                    , title = "Tee Times"
                                    , icon = "icon icon--golf"
                                    , currency = model.config.currency
                                    }
                        , case event |> Dict.values of
                            [] ->
                                text ""

                            events ->
                                MyPackage.toPrice
                                    { price =
                                        events
                                            |> List.map (\e -> e.item |> RemoteData.map (\s -> Event.price s |> Maybe.withDefault 0 |> Money.calculateWithPricePerBuddy ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )) |> RemoteData.withDefault 0)
                                            |> List.foldl (+) 0
                                    , names =
                                        events
                                            |> List.map
                                                (\e ->
                                                    e.item
                                                        |> RemoteData.map Event.title
                                                        |> RemoteData.withDefault ""
                                                )
                                    , title = "Event"
                                    , icon = "icon icon--event"
                                    , currency = model.config.currency
                                    }
                        , case restaurant |> Dict.values of
                            [] ->
                                text ""

                            restaurants ->
                                MyPackage.toPrice
                                    { price =
                                        restaurants
                                            |> List.map
                                                (\e ->
                                                    e.item
                                                        |> RemoteData.map
                                                            (\s ->
                                                                Restaurant.calculatePricePerBuddyOrTotal
                                                                    (Restaurant.getRestaurantPriceType s.price * PackageP.numberOfCashablePerson model.packageParameters)
                                                                    ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                                            )
                                                        |> RemoteData.withDefault 0
                                                )
                                            |> List.foldl (+) 0
                                    , names = restaurants |> List.map (\s -> s.item |> RemoteData.map .name |> RemoteData.withDefault "")
                                    , title = "Restaurant"
                                    , icon = "icon icon--restaurant"
                                    , currency = model.config.currency
                                    }
                        , UIList.item [ UIList.Detail ] li [] <|
                            [ UIList.content div [ class "grid grid--between" ] <|
                                [ span [] []
                                , div [ class "grid__xs" ]
                                    [ UIList.title p [] [ text "Total" ]
                                    ]
                                , UIList.price span
                                    [ class "grid__xs" ]
                                    [ expended |> Money.formatWithoutPrecision model.config.currency |> text
                                    ]
                                ]
                            ]
                        ]
                    ]
                , case model.packageParameters.package.includes |> Maybe.andThen (nextService model.packageParameters model.myPackage) of
                    Just services ->
                        Sidebar.item li [] <|
                            [ div [ class "grid grid--block grid--center" ] [ buildPackage services ]
                            ]

                    Nothing ->
                        text ""
                ]
            ]
        ]
    ]


nextService : PackageP.Parameters -> Maybe MyPackage.MyPackage -> List String -> Maybe Box.BuildPackage.BuildPackage
nextService params mpackage includes =
    mpackage
        |> Maybe.andThen
            (\package ->
                if (includes |> List.map fromString |> List.any (Maybe.map isFlightService >> Maybe.withDefault False)) && Dict.isEmpty package.flight then
                    Just { label = "Flights", href = params |> Route.Flights |> Route.toUrl }

                else if (includes |> List.map fromString |> List.any (Maybe.map isStayService >> Maybe.withDefault False)) && Dict.isEmpty package.hotel then
                    Just { label = "Stays", href = params |> Route.Hotels |> Route.toUrl }

                else if (includes |> List.map fromString |> List.any (Maybe.map isCarService >> Maybe.withDefault False)) && Dict.isEmpty package.car then
                    Just { label = "Cars", href = params |> Route.Cars |> Route.toUrl }
                    -- else if (includes |> List.map fromString |> List.any (Maybe.map isTeeTimeService >> Maybe.withDefault False)) && Dict.isEmpty package.golf then
                    --     Just { label = "Tee Times", href = params |> Route.Golf |> Route.toUrl }
                    -- else if (includes |> List.map fromString |> List.any (Maybe.map isEventService >> Maybe.withDefault False)) && Dict.isEmpty package.event then
                    --     Just { label = "Events", href = params |> Route.Events |> Route.toUrl }
                    -- else if (includes |> List.map fromString |> List.any (Maybe.map isRestaurantService >> Maybe.withDefault False)) && Dict.isEmpty package.restaurant then
                    --     Just { label = "Restaurants", href = params |> Route.Restaurant |> Route.toUrl }

                else
                    Nothing
            )


toDefaultDate : Maybe Date -> Maybe Date -> Maybe Date
toDefaultDate default value =
    case value of
        Just _ ->
            value

        Nothing ->
            default


alertLoader : Bool -> Loader.AlertLoaderModel Msg
alertLoader isLoadingMore =
    { message = "MyBuddiesTrip is searching for more options", isShowed = isLoadingMore, onClose = NoOp }


toSortText : Sorter -> String
toSortText so =
    case so of
        Shortest ->
            "Quickest"

        Price ->
            "Cheapest"

        BestGuestRating ->
            "Reviews"

        DistanceFromTown ->
            "Distance"

        Popular ->
            "Recommended"

        HighestPrice ->
            "Top Rated"

        NoSorter ->
            ""


toMapSorter : List Sorter -> Bool -> Route.Route -> Sorter -> Html Msg
toMapSorter sorters isOpen route sorter =
    let
        sorterText =
            toSortText
    in
    div [ class "map__sort dropdown" ]
        [ a [ class "dropdown__click", href "#", not isOpen |> SorterToggle |> onClick ]
            [ span []
                [ Html.strong []
                    [ sorterText sorter |> text ]
                ]
            , span [ class "dropdown__icon" ]
                [ i [ class "icon icon--chevron-down-outline" ]
                    []
                ]
            ]
        , sorters
            |> List.map (\s -> DropdownItem.item [] li [] [ Dropdown.link a [ href "#", SetSorter route s |> onClick ] [ sorterText s |> text ] ])
            |> DropdownSub.sub [] ul [ classList [ ( "active", isOpen ) ], Attrs.style "z-index" "16" ]
        ]


toSorter : List Sorter -> Bool -> Route.Route -> Sorter -> List (Html Msg)
toSorter possibleSorter isOpen route activeSort =
    let
        sorterText =
            toSortText
    in
    [ Content.sort (Dropdown.dropdown div) [] <|
        [ Dropdown.click span [ classList [ ( "js-active", isOpen ) ], not isOpen |> SorterToggle |> onClick ] <|
            [ a [ href "#" ] [ text "Sort By ", Html.strong [] [ sorterText activeSort |> text ] ]
            , Dropdown.icon span [] [ i [ class "icon icon--chevron-down-outline" ] [] ]
            ]
        , possibleSorter
            |> List.map (\s -> DropdownItem.item [] li [] [ Dropdown.link a [ href "#", SetSorter route s |> onClick ] [ sorterText s |> text ] ])
            |> DropdownSub.sub [] ul [ classList [ ( "js-active", isOpen ) ] ]
        ]
    , mobileSorter (sorterText activeSort) (possibleSorter |> List.map (\s -> { name = sorterText s, onClick = SetSorter route s }))
    ]


hotelPage : Model -> List (Html Msg)
hotelPage model =
    let
        isLoadingMore =
            [ model.hotel.hotelsFromExpedia, model.hotel.hotelsFromHC ]
                |> (\ls -> List.any RemoteData.isLoading ls && List.any RemoteData.isSuccess ls)
    in
    [ Hotel.view model.hotel |> Html.map HotelMsg
    , steps model
    , backToPage model.packageParameters
    , content
        { isMobile = Viewport.Extra.withDefault model.config.viewport
        , topDestinations = notAskedTopDestinations model.topDestinations
        , title = "Stay"
        , ads = model.hotel.ads |> WebData.or model.home.hotelAds |> RemoteData.withDefault [] |> List.map Ads.view
        , cardLoader = li [ class "grid__xs-12" ] [ CardLoader.load { modifier = "cards--load", title = "" } ]
        , destination = model.destination
        , sorter = toSorter [ Price, Popular, BestGuestRating, DistanceFromTown ] model.isSorterOpen model.currentRoute model.hotelSorter
        , dataCount = model.hotel.hotels |> RemoteData.map List.length |> RemoteData.withDefault 0
        , pageCount = model.pageCount
        , data = model.hotel.hotels
        , toHtml = HotelView.card False model.config.currency False (addToPackageAction model) AddSelectedHotel >> List.singleton >> li [ class "grid__xs-12" ]
        , notAskedMessage = "Enter your destination, Check-in and Check-out dates"
        , sort = model.hotelSorter
        , setPageCount = SetPageCount
        , togglePriceMode = ToggleMoneyMode
        , priceMode = model.priceMode
        , toggleFilter = ToggleFilter
        , loader = Just (alertLoader isLoadingMore)
        , toSort = Hotel.hotelSort (Maybe.map (\dest -> Tuple.pair dest.lat dest.long) model.packageParameters.destination)
        , filter =
            sidebar
                { reset = Hotel.ResetFilters |> HotelMsg
                , toggleFilter = ToggleFilter
                , complements = HotelView.sidebarComplements model.displayMap SetDisplayMap
                , filters =
                    HotelView.filter
                        model.config.currency
                        model.formItemDropdown
                        ToggleFormDropdownItem
                        { hotels = model.hotel.originalHotels
                        , parameters = Admin.initHotelFilter model.config model.packageParameters
                        , setMaxPrice = HFilter.SetMaxPrice >> Hotel.FilterMsg >> HotelMsg
                        , setMinPrice = HFilter.SetMinPrice >> Hotel.FilterMsg >> HotelMsg
                        , setHotelStar = HFilter.SetStar >> Hotel.FilterMsg >> HotelMsg
                        , setMaxGuestRating = HFilter.SetMaxGuestRating >> Hotel.FilterMsg >> HotelMsg
                        , setMinGuestRating = HFilter.SetMinGuestRating >> Hotel.FilterMsg >> HotelMsg
                        , setQuery = HFilter.SetQuery >> Hotel.FilterMsg >> HotelMsg
                        , setProvider = HFilter.SetProvider >> Hotel.FilterMsg >> HotelMsg
                        , setPropertyType = HFilter.SetPropertyType >> Hotel.FilterMsg >> HotelMsg
                        , setVacationRentalBedRoom = HFilter.SetVacationRentalBedRoom >> Hotel.FilterMsg >> HotelMsg
                        , activeExcerpt = model.excerptChoices
                        , upsertActiveExcerpt = ToggleExcerpt
                        , providers = model.hotel.hotelProviders
                        , propertyTypes = model.hotel.propertyTypes
                        }
                }
        }
        (showMap model.displayMap
            { close = SetDisplayMap ( not model.displayMap, False )
            , parameters = model.packageParameters
            , mode = model.modalMode
            , setPageCount = SetModalPageCount
            , toggleMode = ChangeMapMode
            , mapUrl = Hotel.buildMapUrl model.config model.onlyAirbnb <| Hotel.toHotelParameters model.config.currency model.packageParameters
            , page = model.modalPage
            , resetFilter = Hotel.ResetFilters |> HotelMsg
            , sorter = toMapSorter [ Price, Popular, BestGuestRating, DistanceFromTown ] model.isSorterOpen model.currentRoute model.hotelSorter
            , cards =
                model.hotel.hotels
                    |> RemoteData.map
                        (\hotels ->
                            hotels
                                |> Hotel.hotelSort (Maybe.map (\destination -> Tuple.pair destination.lat destination.long) model.packageParameters.destination) model.hotelSorter
                                |> List.indexedMap
                                    (\index ->
                                        \hotel ->
                                            { title = hotel.name
                                            , description = hotel.room.name
                                            , other = rating <| Basics.floor (Maybe.withDefault 0 hotel.stars)
                                            , image = List.head hotel.images
                                            , logo =
                                                div [ class "cards__logo" ]
                                                    [ Html.object [ Attrs.attribute "data" <| "./img/logo-" ++ String.toLower hotel.agencyName ++ ".svg" ]
                                                        [ img [ "./img/" ++ hotel.providerImage ++ ".svg" |> src ] [] ]
                                                    ]
                                            , agency = hotel.agencyName
                                            , provided = hotel.providerImage
                                            , price = Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters ) hotel.room.total |> Money.formatWithoutPrecision model.config.currency
                                            , url = hotel.room.link
                                            , onAdd = addToMyPackageMap model (String.fromInt index) (AddSelectedHotel hotel)
                                            }
                                    )
                        )
            , label = "Stays"
            , filters =
                HotelView.filter
                    model.config.currency
                    model.formItemDropdown
                    ToggleFormDropdownItem
                    { hotels = model.hotel.hotels
                    , parameters = Admin.initHotelFilter model.config model.packageParameters
                    , setMaxPrice = HFilter.SetMaxPrice >> Hotel.FilterMsg >> HotelMsg
                    , setMinPrice = HFilter.SetMinPrice >> Hotel.FilterMsg >> HotelMsg
                    , setHotelStar = HFilter.SetStar >> Hotel.FilterMsg >> HotelMsg
                    , setMaxGuestRating = HFilter.SetMaxGuestRating >> Hotel.FilterMsg >> HotelMsg
                    , setMinGuestRating = HFilter.SetMinGuestRating >> Hotel.FilterMsg >> HotelMsg
                    , setQuery = HFilter.SetQuery >> Hotel.FilterMsg >> HotelMsg
                    , setProvider = HFilter.SetProvider >> Hotel.FilterMsg >> HotelMsg
                    , setPropertyType = HFilter.SetPropertyType >> Hotel.FilterMsg >> HotelMsg
                    , setVacationRentalBedRoom = HFilter.SetVacationRentalBedRoom >> Hotel.FilterMsg >> HotelMsg
                    , activeExcerpt = model.excerptChoices
                    , upsertActiveExcerpt = ToggleExcerpt
                    , providers = model.hotel.hotelProviders
                    , propertyTypes = model.hotel.propertyTypes
                    }
            }
        )
    ]


showMap : Bool -> MapModal.Model msg -> Html msg
showMap displayMap mapModel =
    if displayMap then
        MapModal.modalMap mapModel

    else
        text ""


golfPage : Model -> List (Html Msg)
golfPage model =
    [ Golf.view model.golf |> Html.map GolfMsg
    , steps model
    , backToPage model.packageParameters
    , content
        { isMobile = Viewport.Extra.withDefault model.config.viewport
        , topDestinations = notAskedTopDestinations model.topDestinations
        , title = "Golf Course"
        , ads = []
        , destination = model.destination
        , sorter =
            toSorter
                [ Popular
                , Price
                , HighestPrice
                ]
                model.isSorterOpen
                model.currentRoute
                model.golfSorter
        , pageCount = model.pageCount
        , dataCount = model.golf.clubs |> RemoteData.map List.length |> RemoteData.withDefault 0
        , data = model.golf.clubs
        , toHtml =
            Golf.card
                model.config.currency
                False
                (PackageP.numberOfCashablePerson model.packageParameters)
                model.priceMode
                (addToPackageButton model)
                AddSelectedGolf
                >> List.singleton
                >> li [ class "grid__xs-12" ]
        , sort = model.golfSorter
        , togglePriceMode = ToggleMoneyMode
        , priceMode = model.priceMode
        , notAskedMessage = "Enter your destination and travel dates"
        , setPageCount = SetPageCount
        , toggleFilter = ToggleFilter
        , loader = Nothing
        , filter =
            sidebar
                { reset = ResetGolfFilter
                , toggleFilter = ToggleFilter
                , filters = golfFilter model
                , complements =
                    []
                }
        , toSort = golfSort model
        , cardLoader = li [ class "grid__xs-12" ] [ CardLoader.load { modifier = "cards--load", title = "" } ]
        }
        (text "")

    -- , Container.container [ Container.Content ] div [ class "grid", Attrs.style "justify-content" "center" ] <|
    --     [ div [ class "grid__auto empty-state" ]
    --         [ a [ href "https://www.dpbolvw.net/click-8830823-12683882", target "_blank" ]
    --             [ img [ src "https://www.ftjcfx.com/image-8830823-12683882", Attrs.width 468, Attrs.height 60, Attrs.alt "TeeOff Logo" ] []
    --             ]
    --         , h2 [ class "empty-state__title" ] [ text "Start your search" ]
    --         , p [] [ text "Currently, our golf booking page is under construction. We are working hard with our partners to enhance this page to provide you with as many tee time options as possible. In the meantime, please feel free to click on the icon above and browse \n                                the available tee times in your desired location from one of our proud partners, ", a [ href "https://www.dpbolvw.net/click-8830823-12683882", target "_blank" ] [ text "TeeOff.com." ] ]
    --         ]
    --     ]
    ]


golfSort : Model -> Sorter -> List Golf.Club -> List Golf.Club
golfSort _ a b =
    let
        sort =
            \hs ->
                case a of
                    Popular ->
                        hs

                    Price ->
                        List.sortBy (\s -> s.price) hs

                    HighestPrice ->
                        hs |> List.sortBy (\s -> s.price) |> List.reverse

                    _ ->
                        hs
    in
    b
        |> sort


golfFilter : Model -> List (Html Msg)
golfFilter model =
    Golf.filter
        { onQuery = SetGolfQuery
        , parameters = model.packageParameters
        , setMaxPrice = SetGolfMaxPrice
        , setMinPrice = SetGolfMinPrice
        , minPrice = model.golf.clubs |> RemoteData.withDefault [] |> List.map (.price >> Basics.round) |> List.minimum |> Maybe.withDefault 0
        , maxPrice = model.golf.clubs |> RemoteData.withDefault [] |> List.map (.price >> Basics.round) |> List.maximum |> Maybe.withDefault 1000
        , formItemDropdown = model.formItemDropdown
        , toggleFormDropdownItem = ToggleFormDropdownItem
        , currency = model.config.currency
        , setGolfStars = SetGolfStars
        }


hasAtLeastOneSuccessAndOneLoading : List (WebData a) -> Bool
hasAtLeastOneSuccessAndOneLoading ls =
    (ls |> List.any RemoteData.isLoading) && (ls |> List.any RemoteData.isSuccess)


eventPage : Model -> List (Html Msg)
eventPage model =
    let
        isLoadingMore =
            hasAtLeastOneSuccessAndOneLoading [ model.event.eventsFromStubHub ]
    in
    [ Event.view model.event |> Html.map EventMsg
    , steps model
    , backToPage model.packageParameters
    , case model.event.eventFromTicketmaster of
        Failure e ->
            case e of
                Http (BadBody s) ->
                    text s

                _ ->
                    text ""

        _ ->
            text ""
    , content
        { isMobile = Viewport.Extra.withDefault model.config.viewport
        , topDestinations = notAskedTopDestinations model.topDestinations
        , title = "Event"
        , ads = []
        , destination = model.destination
        , sorter = toSorter [ Price, Popular ] model.isSorterOpen model.currentRoute model.eventSorter
        , pageCount = model.pageCount
        , data = model.event.events
        , dataCount = model.event.events |> RemoteData.map List.length |> RemoteData.withDefault 0
        , toHtml =
            Event.card
                False
                False
                (PackageP.numberOfCashablePerson model.packageParameters)
                model.config.currency
                model.priceMode
                (addToPackage model)
                AddSelectedEvent
                (model.event.viatorEventTypes ++ model.event.viatorEventSubTypes)
                (model.event.seatGeekEventTypes |> RemoteData.withDefault [])
                >> List.singleton
                >> li [ class "grid__xs-12" ]
        , sort = model.eventSorter
        , togglePriceMode = ToggleMoneyMode
        , priceMode = model.priceMode
        , notAskedMessage = "Enter your destination and travel dates"
        , setPageCount = SetPageCount
        , toggleFilter = ToggleFilter
        , loader = Just (alertLoader isLoadingMore)
        , filter =
            sidebar
                { reset = Event.ResetEventFilter |> EventMsg
                , toggleFilter = ToggleFilter
                , filters =
                    Event.filter
                        model.config.currency
                        model.formItemDropdown
                        ToggleFormDropdownItem
                        { events = model.event.originalEvents
                        , parameters = model.packageParameters
                        , seatGeekTypes = model.event.seatGeekEventTypes
                        , viatorTypes = model.event.viatorEventTypes
                        , viatorSubTypes = model.event.viatorEventSubTypes
                        , setEventMinPrice = Event.SetEventMinPrice >> EventMsg
                        , setSelectedViatorId = Event.SetSelectedAviatorType >> EventMsg
                        , setEventMaxPrice = Event.SetEventMaxPrice >> EventMsg
                        , setSelectedType = Event.SetSelectedType >> EventMsg
                        , setSelectedSubType = Event.SetSelectedSubType >> EventMsg
                        , setQuery = Event.SetEventQuery >> EventMsg
                        , activeExcerpt = model.excerptChoices
                        , upsertActiveExcerpt = ToggleExcerpt
                        }
                , complements =
                    [ div [ class "grid__xs-12 grid__lg-6" ]
                        [ div [ class "box box--micro box--center box--img-cover box--overlay", Attrs.style "background-image" "url('./img./img-map.jpg')", onClick (SetDisplayMap ( not model.displayMap, False )) ]
                            [ Html.a [ class "box__link", href "#" ]
                                [ i [ class "icon icon-left icon--pin-outline" ] []
                                , text "View Map"
                                ]
                            ]
                        ]
                    ]
                }
        , toSort = Event.sortEvent
        , cardLoader = li [ class "grid__xs-12 grid__md-4" ] [ CardLoader.load { modifier = "cards--load", title = "" } ]
        }
        (showMap model.displayMap
            { close = SetDisplayMap ( not model.displayMap, False )
            , parameters = model.packageParameters
            , mode = model.modalMode
            , toggleMode = ChangeMapMode
            , mapUrl = buildEventMapUrl model.config model.packageParameters
            , page = model.modalPage
            , resetFilter = Event.ResetEventFilter |> EventMsg
            , sorter = toMapSorter [ Price, Popular ] model.isSorterOpen model.currentRoute model.eventSorter
            , cards =
                model.event.events
                    |> RemoteData.map
                        (\events ->
                            events
                                |> Event.sortEvent model.eventSorter
                                |> List.indexedMap
                                    (\index ->
                                        \event ->
                                            { title = Event.title event
                                            , description = Event.description event |> String.toUpper |> String.replace "_" " "
                                            , other = div [ class "cards__time" ] [ text <| Maybe.withDefault "" <| Event.datetime event ]
                                            , image = Event.image event
                                            , agency = Event.providerImage event
                                            , provided = Event.providerImage event
                                            , logo =
                                                Cards.logo div
                                                    []
                                                    [ img [ "./img/" ++ Event.providerImage event ++ ".svg" |> src ] []
                                                    ]
                                            , price =
                                                Event.price event
                                                    |> Maybe.map
                                                        (\s ->
                                                            s
                                                                |> Money.calculateWithPricePerBuddy ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                                                |> Money.formatWithoutPrecision model.config.currency
                                                        )
                                                    |> Maybe.withDefault "no price"
                                            , url = Event.url event
                                            , onAdd = addToMyPackageMap model (String.fromInt index) (AddSelectedEvent event)
                                            }
                                    )
                        )
            , label = "Events"
            , setPageCount = SetModalPageCount
            , filters =
                Event.filter
                    model.config.currency
                    model.formItemDropdown
                    ToggleFormDropdownItem
                    { events = model.event.originalEvents
                    , parameters = model.packageParameters
                    , seatGeekTypes = model.event.seatGeekEventTypes
                    , viatorTypes = model.event.viatorEventTypes
                    , viatorSubTypes = model.event.viatorEventSubTypes
                    , setEventMinPrice = Event.SetEventMinPrice >> EventMsg
                    , setEventMaxPrice = Event.SetEventMaxPrice >> EventMsg
                    , setSelectedType = Event.SetSelectedType >> EventMsg
                    , setSelectedSubType = Event.SetSelectedSubType >> EventMsg
                    , setSelectedViatorId = Event.SetSelectedAviatorType >> EventMsg
                    , setQuery = Event.SetEventQuery >> EventMsg
                    , activeExcerpt = model.excerptChoices
                    , upsertActiveExcerpt = ToggleExcerpt
                    }
            }
        )

    --  ( model.displayMap, buildEventMapUrl model.packageParameters )
    ]


restaurantPage : Model -> List (Html Msg)
restaurantPage model =
    [ Restaurant.view model.restaurant |> Html.map RestaurantMsg
    , steps model
    , backToPage model.packageParameters
    , content
        { isMobile = Viewport.Extra.withDefault model.config.viewport
        , topDestinations = notAskedTopDestinations model.topDestinations
        , title = "Restaurant"
        , ads = []
        , destination = model.destination
        , sorter = toSorter [ Popular, Price, HighestPrice ] model.isSorterOpen model.currentRoute model.restaurantSorter
        , pageCount = model.pageCount
        , dataCount = model.restaurant.restaurantsCount |> RemoteData.withDefault 0
        , data =
            model.restaurant.restaurants
                |> RemoteData.map
                    (\rs ->
                        rs
                            |> List.indexedMap
                                (\index ->
                                    \resto ->
                                        { restId = resto.restId
                                        , name = resto.name
                                        , cuisineType = resto.cuisineType
                                        , establishments = resto.establishments
                                        , mobileReserveUrl = resto.mobileReserveUrl
                                        , price = resto.price
                                        , imageUrl2 = Nothing
                                        , imageUrl =
                                            resto.imageUrl
                                                |> Maybe.withDefault (resto.imageUrl2 |> Maybe.withDefault "")
                                                |> (\s ->
                                                        if String.isBlank s then
                                                            Zomato.images index |> Just

                                                        else
                                                            Just s
                                                   )
                                        , address = resto.address
                                        , providerImage = resto.providerImage
                                        , city = resto.city
                                        , area = resto.area
                                        , postal_code = resto.postal_code
                                        , country = resto.country
                                        , reserveUrl = Route.toUrl <| Route.Redirect <| Route.RedirectParams "restaurant" resto.reserveUrl
                                        , phone = resto.phone
                                        , review = resto.review
                                        , reviewCount = resto.reviewCount
                                        }
                                )
                    )
        , toHtml =
            Restaurant.restaurantCard
                False
                False
                (PackageP.numberOfCashablePerson model.packageParameters)
                model.config.currency
                model.priceMode
                (addToPackage model)
                AddSelectedRestaurant
                >> List.singleton
                >> li [ class "grid__xs-12" ]
        , sort = model.restaurantSorter
        , togglePriceMode = ToggleMoneyMode
        , priceMode = model.priceMode
        , notAskedMessage = "Enter your destination"
        , setPageCount = SetPageCount
        , toggleFilter = ToggleFilter
        , loader = Nothing
        , filter =
            sidebar
                { reset = Restaurant.ResetRestaurantFilter |> RestaurantMsg
                , toggleFilter = ToggleFilter
                , filters =
                    Restaurant.restaurantfilter
                        model.formItemDropdown
                        ToggleFormDropdownItem
                        { restaurants = model.restaurant.restaurants
                        , parameters = model.restaurant.parameters
                        , setPriceFilter = Restaurant.SeacrhByPrice >> RestaurantMsg
                        , setRestaurantQuery = Restaurant.SetRestaurantQuery >> RestaurantMsg
                        , cuisines = RemoteData.withDefault [] model.restaurant.cuisines
                        , establishments = RemoteData.withDefault [] model.restaurant.establishments
                        , categories = RemoteData.withDefault [] model.restaurant.categories
                        , setCuisineFilter = Restaurant.SetRestaurantCuisine >> RestaurantMsg
                        , setCategoryFilter = Restaurant.SetCategoryCuisine >> RestaurantMsg
                        , setEstablishmentFilter = Restaurant.SetEstablishmentsCuisine >> RestaurantMsg
                        , activeExcerpt = model.excerptChoices
                        , upsertActiveExcerpt = ToggleExcerpt
                        }
                , complements =
                    [ div [ class "grid__xs-12 grid__lg-6" ]
                        [ div [ class "box box--micro box--center box--img-cover box--overlay", Attrs.style "background-image" "url('./img./img-map.jpg')", onClick (SetDisplayMap ( not model.displayMap, False )) ]
                            [ Html.a [ class "box__link", href "#" ]
                                [ i [ class "icon icon-left icon--pin-outline" ] []
                                , text "View Map"
                                ]
                            ]
                        ]
                    ]
                }
        , cardLoader = li [ class "grid__xs-12 grid__md-4" ] [ CardLoader.load { modifier = "cards--load", title = "" } ]
        , toSort = sortRestaurant
        }
        (showMap model.displayMap
            { close = SetDisplayMap ( not model.displayMap, False )
            , parameters = model.packageParameters
            , mode = model.modalMode
            , toggleMode = ChangeMapMode
            , mapUrl = Zomato.buildRestaurantMapUrl model.config <| Zomato.toRestaurantParameters model.packageParameters 0 model.restaurantSorter
            , page = model.modalPage
            , sorter = toMapSorter [ Popular, Price, HighestPrice ] model.isSorterOpen model.currentRoute model.restaurantSorter
            , cards =
                model.restaurant.restaurants
                    |> RemoteData.map
                        (\restaurants ->
                            restaurants
                                |> sortRestaurant model.restaurantSorter
                                |> List.indexedMap
                                    (\index ->
                                        \restaurant ->
                                            { title = restaurant.name
                                            , description = String.join ", " (restaurant.cuisineType |> List.map .name)
                                            , other = div [ class "cards__time" ] [ text "" ]
                                            , image =
                                                restaurant.imageUrl
                                                    |> Maybe.withDefault ""
                                                    |> String.isBlank
                                                    |> (\s ->
                                                            if s then
                                                                Zomato.images index |> Just

                                                            else
                                                                restaurant.imageUrl
                                                       )
                                            , agency = restaurant.providerImage
                                            , provided = restaurant.providerImage
                                            , logo =
                                                Cards.logo div
                                                    []
                                                    [ img [ "" |> src ] []
                                                    ]
                                            , price =
                                                Restaurant.calculatePricePerBuddyOrTotal
                                                    (Restaurant.getRestaurantPriceType restaurant.price
                                                        * PackageP.numberOfCashablePerson model.packageParameters
                                                    )
                                                    ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                                                    |> Money.formatWithoutPrecision model.config.currency
                                            , url = restaurant.reserveUrl
                                            , onAdd = addToMyPackageMap model (String.fromInt index) (AddSelectedRestaurant restaurant)
                                            }
                                    )
                        )
            , label = "Restaurant"
            , setPageCount = SetModalPageCount
            , resetFilter = Restaurant.ResetRestaurantFilter |> RestaurantMsg
            , filters =
                Restaurant.restaurantfilter
                    model.formItemDropdown
                    ToggleFormDropdownItem
                    { restaurants = model.restaurant.restaurants
                    , parameters = model.packageParameters
                    , setPriceFilter = Restaurant.SeacrhByPrice >> RestaurantMsg
                    , setRestaurantQuery = Restaurant.SetRestaurantQuery >> RestaurantMsg
                    , cuisines = RemoteData.withDefault [] model.restaurant.cuisines
                    , establishments = RemoteData.withDefault [] model.restaurant.establishments
                    , categories = RemoteData.withDefault [] model.restaurant.categories
                    , setCuisineFilter = Restaurant.SetRestaurantCuisine >> RestaurantMsg
                    , setCategoryFilter = Restaurant.SetCategoryCuisine >> RestaurantMsg
                    , setEstablishmentFilter = Restaurant.SetEstablishmentsCuisine >> RestaurantMsg
                    , activeExcerpt = model.excerptChoices
                    , upsertActiveExcerpt = ToggleExcerpt
                    }
            }
        )
    ]


sortRestaurant : Sorter -> List Restaurant.RestaurantType -> List Restaurant.RestaurantType
sortRestaurant _ b =
    b


currencyToUrl : Money.Currency -> String
currencyToUrl currency =
    case currency of
        Money.USD ->
            "USD"

        Money.CAD ->
            "CAD"


buildEventMapUrl : Config.Config -> PackageP.Parameters -> String
buildEventMapUrl config params =
    Builder.crossOrigin urlMap
        [ "embed", "gm" ]
        [ Builder.string "maincolor" "00B7E8"
        , Builder.string "aid" "mybuddiestrip-t1"
        , Builder.string "hidebrandlogo" "true"
        , Builder.string "lat" <| Maybe.withDefault "" <| Maybe.map String.fromFloat <| Maybe.map .lat <| params.destination
        , Builder.string "lng" <| Maybe.withDefault "" <| Maybe.map String.fromFloat <| Maybe.map .long <| params.destination
        , Builder.string "checkin" <| Date.toIsoDateOrEmpty params.departureDate
        , Builder.string "checkout" <| Date.toIsoDateOrEmpty params.returnDate
        , Builder.string "min" <| String.fromInt <| Maybe.withDefault 0 params.event.minPrice
        , Builder.string "max" <| String.fromInt <| Maybe.withDefault 1000 params.event.maxPrice

        -- , Builder.string "minstar" <| String.fromInt <| Maybe.withDefault 0 stars
        , Builder.string "language" "en"
        , Builder.string "currency" <| currencyToUrl config.currency
        , Builder.string "radius" "50000"
        , Builder.string "sortby" "price"
        , Builder.string "sortdirection" "descending"
        , Builder.string "limit" "100"
        , Builder.string "hidefilters" "true"

        -- , Builder.string "hidecheckinout" "true"
        , Builder.string "hideadults" "true"
        , Builder.string "hidechildren" "true"
        , Builder.string "hideguestpicker" "true"
        , Builder.string "disableservices" "accommodation,carrental,restaurant,parking"
        , Builder.string "invmode" "experience"
        , Builder.string "hidemodeswitcher" "true"
        ]


carPage : Model -> List (Html Msg)
carPage model =
    [ Car.view model.car |> Html.map CarMsg
    , steps model
    , backToPage model.packageParameters
    , content
        { isMobile = Viewport.Extra.withDefault model.config.viewport
        , topDestinations = notAskedTopDestinations model.topDestinations
        , title = "Car"
        , ads = model.car.ads |> WebData.or model.home.carAds |> RemoteData.withDefault [] |> List.map CarAds.view
        , destination = model.destination
        , sort = model.carSorter
        , toSort = sortCar
        , sorter = toSorter [ Price, Popular ] model.isSorterOpen (Route.Cars model.packageParameters) model.carSorter
        , pageCount = model.pageCount
        , dataCount = model.car.cars |> RemoteData.map List.length |> RemoteData.withDefault 0
        , data = model.car.cars
        , toHtml =
            Car.card model.config.currency
                False
                ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters )
                (addToPackageButton model)
                AddSelectedCar
                >> List.singleton
                >> li [ class "grid__xs-12" ]
        , notAskedMessage =
            "Enter your destination, Pick-up and Drop-off dates"
        , toggleFilter = ToggleFilter
        , setPageCount = SetPageCount
        , togglePriceMode = ToggleMoneyMode
        , priceMode = model.priceMode
        , loader = Nothing
        , filter =
            sidebar
                { reset = Car.ResetCarFilter |> CarMsg
                , toggleFilter = ToggleFilter
                , filters = carFilter model
                , complements =
                    [ div [ class "grid__xs-12 grid__lg-6" ]
                        [ div [ class "box box--micro box--center box--img-cover box--overlay", Attrs.style "background-image" "url('./img./img-map.jpg')", onClick (SetDisplayMap ( not model.displayMap, False )) ]
                            [ Html.a [ class "box__link", href "#" ]
                                [ i [ class "icon icon-left icon--pin-outline" ] []
                                , text "View Map"
                                ]
                            ]
                        ]
                    ]
                }
        , cardLoader = li [ class "grid__xs-12" ] [ CardLoader.load { modifier = "cards--load", title = "" } ]
        }
        (showMap model.displayMap
            { close = SetDisplayMap ( not model.displayMap, False )
            , parameters = model.packageParameters
            , mode = model.modalMode
            , toggleMode = ChangeMapMode
            , mapUrl = buildCarMapUrl model.config model.packageParameters
            , page = model.modalPage
            , resetFilter = Car.ResetCarFilter |> CarMsg
            , sorter = toMapSorter [ Price, Popular ] model.isSorterOpen model.currentRoute model.carSorter
            , cards =
                model.car.cars
                    |> RemoteData.map
                        (\cars ->
                            cars
                                |> sortCar model.carSorter
                                |> List.indexedMap
                                    (\index ->
                                        \car ->
                                            { title = car.car.name
                                            , description = car.car.type_
                                            , other = car.car.passengers |> (\s -> String.fromInt s ++ " passengers" |> text)
                                            , image = Just car.car.image
                                            , agency = car.vendorImage
                                            , provided = car.vendorImage
                                            , logo = div [ class "cards__logo" ] [ img [ Attrs.height 20, Attrs.width 50, car.vendorImage |> src ] [] ]
                                            , price = Money.calculateWithTotalPrice ( model.priceMode, PackageP.numberOfCashablePerson model.packageParameters ) car.price.totalPrice |> Money.formatWithoutPrecision model.config.currency
                                            , url = car.link
                                            , onAdd = addToMyPackageMap model (String.fromInt index) (AddSelectedCar car)
                                            }
                                    )
                        )
            , label = "Cars"
            , setPageCount = SetModalPageCount
            , filters = carFilter model
            }
        )

    -- ( model.displayMap, buildCarMapUrl model.packageParameters )
    ]


carFilter : Model -> List (Html Msg)
carFilter model =
    Car.filter
        model.config.currency
        model.formItemDropdown
        ToggleFormDropdownItem
        { originalCars = model.car.originalCars
        , parameters = model.packageParameters
        , setMaxPriceCar = Car.SetMaxPriceCar >> CarMsg
        , setMinPriceCar = Car.SetMinPriceCar >> CarMsg
        , setMaxCapacity = Car.SetMaxCapacity >> CarMsg
        , setMinCapacity = Car.SetMinCapacity >> CarMsg
        , setCarType = Car.SetCarType >> CarMsg
        , setCarVendor = Car.SetCarVendor >> CarMsg
        , setCarInsurance = Car.SetCarInsurance >> CarMsg
        , setCarOption = Car.SetCarOption >> CarMsg
        , carVendors = model.car.carVendors
        , setQuery = Car.SetCarQuery >> CarMsg
        , activeExcerpt = model.excerptChoices
        , upsertActiveExcerpt = ToggleExcerpt
        }


sortCar : Sorter -> List Car.CarRental -> List Car.CarRental
sortCar a b =
    case a of
        Price ->
            List.sortBy (\x -> x.price.totalPrice) b

        Popular ->
            b
                |> List.sortWith
                    (\car1 ->
                        \car2 ->
                            let
                                isFull =
                                    \insu -> List.member (String.fromInt insu.id) Car.fullInsurance
                            in
                            if (car1.insurances |> List.any isFull) && (car2.insurances |> List.any isFull) then
                                Basics.EQ

                            else if car1.insurances |> List.any isFull then
                                Basics.GT

                            else
                                Basics.LT
                    )

        _ ->
            b


urlMap : String
urlMap =
    "https://www.stay22.com"


buildCarMapUrl : Config.Config -> PackageP.Parameters -> String
buildCarMapUrl config params =
    -- let
    --     returnedUrl =
    --         urlMap
    --             ++ "&lat="
    --             ++ String.fromFloat (params.destination.lat |> Maybe.withDefault 0)
    --             ++ "&lng="
    --             ++ String.fromFloat (params.destination.long |> Maybe.withDefault 0)
    --             ++ "&checkin="
    --             ++ Date.toIsoDateOrEmpty params.departureDate
    --             ++ "&checkout="
    --             ++ Date.toIsoDateOrEmpty params.returnDate
    --             ++ "&minprice=0&maxprice=1200&language=en&currency=CAD&radius=15000&disableservices=accommodation,experience,restaurant,parking&invmode=carrental&hidemodeswitcher=true"
    -- in
    -- returnedUrl
    Builder.crossOrigin urlMap
        [ "embed", "gm" ]
        [ Builder.string "maincolor" "00B7E8"
        , Builder.string "aid" "mybuddiestrip-t1"
        , Builder.string "hidebrandlogo" "true"
        , Builder.string "lat" <| Maybe.withDefault "" <| Maybe.map String.fromFloat <| Maybe.map .lat <| params.destination
        , Builder.string "lng" <| Maybe.withDefault "" <| Maybe.map String.fromFloat <| Maybe.map .long <| params.destination
        , Builder.string "checkin" <| Date.toIsoDateOrEmpty params.car.pickupDate
        , Builder.string "checkout" <| Date.toIsoDateOrEmpty params.car.dropoffDate
        , Builder.string "min" <| String.fromInt <| Maybe.withDefault 0 params.car.minPricePerDay
        , Builder.string "max" <| String.fromInt <| Maybe.withDefault 1000 params.car.maxPricePerDay

        -- , Builder.string "minstar" <| String.fromInt <| Maybe.withDefault 0 stars
        , Builder.string "language" "en"
        , Builder.string "currency" <| currencyToUrl config.currency
        , Builder.string "radius" "50000"
        , Builder.string "sortby" "price"
        , Builder.string "sortdirection" "descending"
        , Builder.string "limit" "100"
        , Builder.string "hidefilters" "true"

        -- , Builder.string "hidecheckinout" "true"
        , Builder.string "hideadults" "true"
        , Builder.string "hidechildren" "true"
        , Builder.string "hideguestpicker" "true"
        , Builder.string "hidecheckinout" "true"
        , Builder.string "disableservices" "accommodation,experience,restaurant,parking"
        , Builder.string "invmode" "carrental"
        , Builder.string "hidemodeswitcher" "true"
        ]


backToPage : PackageP.Parameters -> Html Msg
backToPage packageParameters =
    if True == True then
        text ""

    else
        div [ class "container" ]
            [ a [ class "btn btn--back", Route.MyPackage packageParameters |> Route.toUrl |> href ]
                [ i [ class "icon icon--arrow-back-outline" ] []
                , text "Back to My Package"
                ]
            ]


flightPage : Model -> List (Html Msg)
flightPage model =
    let
        isLoadingMore =
            model.flight.loadingMore |> Maybe.isJust
    in
    []
        ++ (Flight.view model.flight
                |> List.map (Html.map FlightMsg)
           )
        ++ [ steps model
           , backToPage model.packageParameters
           , content
                { isMobile = Viewport.Extra.withDefault model.config.viewport
                , topDestinations = notAskedTopDestinations model.topDestinations
                , title = "Flight"
                , ads = model.flight.ads |> WebData.or model.home.flightAds |> RemoteData.withDefault [] |> List.map Flight.Kayak.Ads.view
                , destination = model.destination
                , sorter = toSorter [ Price, Shortest ] model.isSorterOpen model.currentRoute model.flightSorter
                , pageCount = model.pageCount
                , data = model.flight.flights
                , dataCount = model.flight.flights |> RemoteData.map List.length |> RemoteData.withDefault 0
                , toHtml = \t -> li [ class "grid__xs-12 margin-gutter" ] [ Flight.card False "flight" False (PackageP.numberOfCashablePerson model.packageParameters) model.config.currency (addToPackageButton model) model.zone AddSelectFlight t ]
                , notAskedMessage = "Enter your destination and departure date"
                , sort = model.flightSorter
                , setPageCount = SetPageCount
                , togglePriceMode = ToggleMoneyMode
                , toggleFilter = ToggleFilter
                , priceMode = model.priceMode
                , loader = Just (alertLoader isLoadingMore)
                , filter =
                    sidebar
                        { reset = Flight.ResetFlightFilter |> FlightMsg
                        , toggleFilter = ToggleFilter
                        , complements = []
                        , filters =
                            Flight.filter
                                model.config.currency
                                model.formItemDropdown
                                ToggleFormDropdownItem
                                { minPrice =
                                    model.flight.originalFlights
                                        |> RemoteData.withDefault []
                                        |> List.map .price
                                        |> List.sort
                                        |> List.head
                                        |> Maybe.map ceiling
                                        |> Maybe.withDefault 0
                                , maxPrice =
                                    model.flight.originalFlights
                                        |> RemoteData.withDefault []
                                        |> List.map .price
                                        |> List.sort
                                        |> List.reverse
                                        |> List.head
                                        |> Maybe.map ceiling
                                        |> Maybe.withDefault 10000
                                , parameters = Flight.toFlightParameters model.packageParameters
                                , setFlightMinPrice = FFilter.SetFlightMinPrice >> Flight.FilterMsg >> FlightMsg
                                , setFlightMaxPrice = FFilter.SetFlightMaxPrice >> Flight.FilterMsg >> FlightMsg
                                , setStopOvers = FFilter.SetStopOvers >> Flight.FilterMsg >> FlightMsg
                                , setDepartureTimeMin = FFilter.SetDepartureTimeMin >> Flight.FilterMsg >> FlightMsg
                                , setDepartureTimeMax = FFilter.SetDepartureTimeMax >> Flight.FilterMsg >> FlightMsg
                                , setArrivalTimeMin = FFilter.SetArrivalTimeMin >> Flight.FilterMsg >> FlightMsg
                                , setArrivalTimeMax = FFilter.SetArrivalTimeMax >> Flight.FilterMsg >> FlightMsg
                                , setReturnDepartureTimeMin = FFilter.SetReturnDepartureTimeMin >> Flight.FilterMsg >> FlightMsg
                                , setReturnDepartureTimeMax = FFilter.SetReturnDepartureTimeMax >> Flight.FilterMsg >> FlightMsg
                                , setReturnArrivalTimeMin = FFilter.SetReturnArrivalTimeMin >> Flight.FilterMsg >> FlightMsg
                                , setReturnArrivalTimeMax = FFilter.SetReturnArrivalTimeMax >> Flight.FilterMsg >> FlightMsg
                                , setSelectedAirline = FFilter.SetSelectedAirline >> Flight.FilterMsg >> FlightMsg
                                , typedAirline = model.typedAirline
                                , airlines = model.flight.airlines
                                , from = model.from
                                , flightDestination = model.flightDestination
                                , activeExcerpt = model.excerptChoices
                                , upsertActiveExcerpt = ToggleExcerpt
                                }
                        }
                , cardLoader = li [ class "grid__xs-12 margin-gutter" ] [ CardLoader.load { modifier = "cards--horizontal cards--load", title = "" } ]
                , toSort =
                    \sort ->
                        \ls ->
                            ls
                                |> (\b ->
                                        case sort of
                                            Shortest ->
                                                List.sortBy .duration b

                                            Price ->
                                                List.sortBy .price b

                                            _ ->
                                                b
                                   )
                }
                (text "")
           ]


addToPackageAction : Model -> Msg -> List (Html.Attribute Msg)
addToPackageAction _ msg =
    [ onClick msg, "#" |> href ]


addToPackage : Model -> String -> Msg -> Html Msg
addToPackage model _ msg =
    Button.button { label = "Add To My Package", modifier = "btn--primary btn--sm btn--border", actions = addToPackageAction model msg }


addToPackageButton : Model -> String -> Msg -> Html Msg
addToPackageButton model _ msg =
    a (addToPackageAction model msg)
        [ button [ class "mbt-button mbt-button--outline" ]
            [ i [ class "mbt-card__icon icon icon--plus-outline" ]
                []
            , text "Add to package"
            ]
        ]


addToMyPackageMap : Model -> String -> Msg -> Html Msg
addToMyPackageMap model index msg =
    div [ class "cards__dropdown" ]
        [ div [ class "dropdown" ]
            [ ButtonRounded.buttonRounded { modifier = "btn--primary btn-micro btn--rounded dropdown__click", icon = "more-horizontal-outline", action = onClick (ToggleCardOption (Just index)), openInNewOnglet = False }
            , ul [ class "dropdown__sub", classList [ ( "active js-open", model.idToOpenOptions |> Maybe.map ((==) index) |> Maybe.withDefault False ) ] ]
                [ li [ class "dropdown__item", onClick msg ]
                    [ a [ class "dropdown__link", Route.MyPackage model.packageParameters |> Route.toUrl |> href ] [ i [ class "icon icon--package" ] [], text "Add To My Package" ]
                    ]
                ]
            ]
        ]


golfQueryDebouncerConfig : Debounce.Config Msg
golfQueryDebouncerConfig =
    { strategy = Debounce.later 1000
    , transform = DebouncedGolfQuery
    }


type alias Flags =
    { language : String
    , date : String
    , now : Posix
    , url : String
    , isSubscribed : Bool
    , lastestSubscriptionDemanded : String
    , user : AuthenticationState
    , callbackUrl : String
    , currency : String
    , width : Int
    , disabledProviders : DisabledProvider
    , brand : String
    , context : String
    }


defaultFlags : Flags
defaultFlags =
    { language = "en"
    , date = ""
    , url = ""
    , now = 0 |> Time.millisToPosix
    , isSubscribed = False
    , lastestSubscriptionDemanded = ""
    , user = Auth0.LoggedOut
    , callbackUrl = ""
    , currency = "CAD"
    , width = 0
    , disabledProviders =
        { seatGeek = False
        , viator = False
        , stubhub = True
        , ticketMaster = False
        }
    , brand = "mybuddiestrip"
    , context = "master"
    }


decodeFlags : Decode.Decoder Flags
decodeFlags =
    Decode.succeed Flags
        |> Decode.optional "language" Decode.string defaultFlags.language
        |> Decode.optional "date" Decode.string defaultFlags.date
        |> Decode.required "now" (Decode.int |> Decode.map Time.millisToPosix)
        |> Decode.optional "url" Decode.string defaultFlags.url
        |> Decode.optional "isSubscribed" Decode.bool defaultFlags.isSubscribed
        |> Decode.optional "lastestSubscriptionDemanded" Decode.string defaultFlags.lastestSubscriptionDemanded
        |> Decode.optional "user" decodeAuthenticationState defaultFlags.user
        |> Decode.optional "callbackUrl" Decode.string defaultFlags.callbackUrl
        |> Decode.optional "currency" Decode.string defaultFlags.currency
        |> Decode.optional "width" Decode.int defaultFlags.width
        |> Decode.optional "disabledProviers" disabledProviderDecoder defaultFlags.disabledProviders
        |> Decode.optional "brand" Decode.string defaultFlags.brand
        |> Decode.optional "context" Decode.string defaultFlags.brand


disabledProviderDecoder : Decode.Decoder DisabledProvider
disabledProviderDecoder =
    Decode.succeed DisabledProvider
        |> Decode.optional "seatGeek" Decode.bool defaultFlags.disabledProviders.seatGeek
        |> Decode.optional "viator" Decode.bool defaultFlags.disabledProviders.viator
        |> Decode.optional "ticketMaster" Decode.bool defaultFlags.disabledProviders.viator
        |> Decode.optional "stubhub" Decode.bool defaultFlags.disabledProviders.viator


homeDebouncerConfig : Debounce.Config Msg
homeDebouncerConfig =
    { strategy = Debounce.later 350
    , transform = DebounceFromQuery
    }


init : Decode.Value -> Url -> Nav.Key -> ( Model, Cmd Msg )
init value url key =
    let
        flag =
            case Decode.decodeValue decodeFlags value of
                Ok t ->
                    t

                Err _ ->
                    defaultFlags

        ( model, cmds ) =
            update (ChangedUrl url) (initModel key flag)

        ( _, datePickerFxDp ) =
            DPD.init

        -- TODO: Investigate why we need to init flight here!
        ( flightModel, flightCmd ) =
            Flight.init model.zone model.config model.packageParameters

        authCmd =
            case flag.user of
                Auth0.LoggedIn state ->
                    if Auth0.isExpired flag.now state then
                        auth0logout (Route.SignUp |> Route.toUrl |> Just)

                    else
                        Cmd.none

                Auth0.LoggedOut ->
                    Cmd.none
    in
    ( { model | flight = flightModel }
    , Cmd.batch
        [ cmds
        , Task.perform SetZone Time.here
        , Cmd.map ToDoubleDatePicker datePickerFxDp
        , Cmd.map (Admin.ToDoubleDatePicker >> ToAdminPage) datePickerFxDp
        , if model.config.disabledProviders.viator |> not then
            (getCategories model.config.domain Nothing |> RemoteData.asCmd |> Cmd.map Event.HandleViatorCategories) |> Cmd.map EventMsg

          else
            Cmd.none
        , fetchPackage ()
        , Task.perform SetDate Date.today
        , Task.perform SetApplicationOpenedAt Time.now
        , Rates.getRate model.config.domain HandleRates
        , Promotion.get model.config.domain HandlePromotion
        , Dom.getViewport |> Task.perform HandleViewport
        , flightCmd |> Cmd.map FlightMsg
        , MyPrismic.App.fetchApp model.prismic model.config.brand HandlePrismicApp
        , MyPackage.fetchMyPackage model.config HandleMyPackage
        , authCmd
        ]
    )


main : Program Decode.Value Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        , subscriptions = subscriptions
        , update = update
        , view = view
        }
