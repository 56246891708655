"use strict";

// require("./styles/font");
var $ = require("jquery");
import { Auth0Client } from "@auth0/auth0-spa-js";
const Util = {
  addClass: function (el, className) {
    var classList = className.split(' ');
    el.classList.add(classList[0]);
    if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
  },

  removeClass: function (el, className) {
    var classList = className.split(' ');
    el.classList.remove(classList[0]);
    if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
  },
  hasClass: function (el, className) {
    return el.classList.contains(className);
  }
}

function inViewport($el) {
  var H = $(window).height(),
    r = $el[0] && $el[0].getBoundingClientRect(),
    t = r && r.top,
    b = r && r.bottom;

  return Math.max(0, t > 0 ? H - t : b < H ? b : H);
}
var { Elm } = require("./Main.elm");
var webAuth = new Auth0Client({
  domain: process.env.DOMAIN,
  client_id: process.env.CLIENT_ID,
  audience: process.env.AUTH_AUDIENCE,
  scope: "openid profile user_metadata",
  redirect_uri: `${window.location.origin}/callback`,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
});


const flags = {
  language: window.navigator.userLanguage || window.navigator.language,
  date: new Date().toISOString().slice(0, 10),
  now: Date.now(),
  url: process.env.DOMAIN_URL,
  isSubscribed: localStorage.getItem("isSubscribed") === "true",
  lastestSubscriptionDemanded: localStorage.getItem("lastestSubscriptionDemanded") || "",
  user: JSON.parse(localStorage.getItem("user")),
  callbackUrl: localStorage.getItem("callbackUrl") || "",
  currency: localStorage.getItem("currency") || "",
  width: window.screen.width,
  brand: process.env.BRAND || "",
  disabledProviers: {
    seatGeek: process.env.SEATGEEK_TOGGLER === "off",
    viator: process.env.VIATOR_TOGGLER === "off",
    ticketMaster: process.env.TICKERMASTER_TOGGLER === "off",
    stubhub: process.env.STUBHUB_TOGGLER === "off"
  },
  context: process.env.HEAD || "",
};

var elmApp = Elm.Main.init({
  node: document.getElementById("root"),
  flags,
});

var getUserData = async () => {
  const isAuthenticated = await webAuth.isAuthenticated();
  if (isAuthenticated) {
    var user = await webAuth.getUser();
    var token = await webAuth.getTokenSilently();
    const parsedUser = {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      postalCode: "",
      state: "",
      zipCode: "",
      ...user,
      ...user["https://mybuddiestrip.com/user_metadata"],
      roles: user["https://mybuddiestrip.com/roles"] || [],
    };
    const resp = { profile: parsedUser, token: token };
    localStorage.setItem("user", JSON.stringify(resp));
    return resp;
  }
};

$(window).on("scroll resize", function () {
  var window_offset = inViewport($(".hero--home"));

  elmApp.ports.handleScrollOrResize.send({
    homeHeaderHeight: window_offset,
  });

  initNav();
});



elmApp.ports.savePackageDropdown.subscribe(([p, shouldOpenCart]) => {
  localStorage.setItem("package", JSON.stringify(p));

  if (shouldOpenCart) {
    const selector = window.matchMedia("(max-width: 1023px)").matches ? $("#my-package-mobile-dropdown-id") : $("#my-package-dropdown-id");
    window.requestAnimationFrame(() => {
      $(selector).children(".mbt-dropdown__click").toggleClass("js-active");
    });

    $(document).on("click", function (e) {
      if (!$(selector).has(e.target).length) {
        $(".dropdown__click.js-active, .mbt-dropdown__click.js-active").removeClass("js-active");
      }

      $(document).off("click");
    });
  }
});

elmApp.ports.fetchPackage.subscribe(() => {
  elmApp.ports.handlePackage.send(JSON.parse(localStorage.getItem("package")));
});


elmApp.ports.saveCurrency.subscribe((currency) => {
  localStorage.setItem("currency", currency);
  // window.location.reload();
});

// elmApp.ports.toggleCarousel.subscribe(function (toggleCarousel) {
//   if (toggleCarousel) {
//     window.requestAnimationFrame(function () {
//       var element = document.getElementsByClassName("carousel")[0];
//       var flkty = new Flickity(element, {
//         wrapAround: true,
//         autoPlay: true,
//         cellAlign: "center",
//       });
//     });
//   }
// });
var _loaded = {};

var swiper = null

const initSwiper = () => {
  window.requestAnimationFrame(() => {
    const isThere = document.getElementById("swiper-hero")
    if (!isThere) swiper = null;
    if (isThere && !swiper)
      swiper = new Swiper(".mySwiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1280: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      });
  })
}

elmApp.ports.onRouteChange.subscribe(() => {
  initSwiper();
  const trustbox = document.getElementById("trustbox");
  if (trustbox && window.Trustpilot) window.Trustpilot.loadFromElement(trustbox);
  const wetravel = document.getElementById("we-travel-embeded-id");
  if (wetravel) {
    window.requestAnimationFrame(() => {
      const url = "https://cdn.wetravel.com/widgets/embed_all_trip.js"
      var s = document.createElement('script');

      s.src = url;
      s.setAttribute("id", "wetravel_listing");
      s.setAttribute("data-env", "https://www.wetravel.com")
      s.setAttribute("data-version", "v0.3")
      s.setAttribute("data-uid", "316760")
      s.setAttribute("data-slug", "bryce-swan")
      s.setAttribute("data-color", "33ae3f")
      s.setAttribute("data-text", "Details")
      s.setAttribute("data-name", "My Trips")
      // const t = <script src="https://cdn.wetravel.com/widgets/embed_all_trip.js" id="wetravel_listing" data-env="https://www.wetravel.com" data-version="v0.3" data-uid="316760" data-slug="bryce-swan" data-color="33ae3f" data-text="Details" data-name="My Trips"></script>
      wetravel.appendChild(s);

      // const wetravelTrips = document.getElementById("we-travel-embeded-id").childNodes[0];
      // const wetravelTrips = document.getElementById("we-travel-embeded-id").firstChild;
      // const wetravelTrips = document.getElementById("__next");
      const wetravelTrips = wetravel.getElementsByTagName('iframe');
      // wetravelTrips.style.maxHeight = "100px"
    })
  }
});



window.addEventListener('DOMContentLoaded', () => {
  initSwiper()

  // menu mobile
  $(".burger__toggle, .mbt-burger__toggle").change(function () {
    if ($(this).is(':checked')) {
      $("body, html").addClass("active-menu");
      $(".header, .mbt-header").addClass("js-active");
    } else {
      $("body, html").removeClass("active-menu");
      $(".header, .mbt-header").removeClass("js-active");
    };
  });
})


const initNav = function () {

  var hidingNav = document.getElementsByClassName('js-hide-nav');
  if (hidingNav.length > 0 && window.requestAnimationFrame) {
    var mainNav = Array.prototype.filter.call(hidingNav, function (element) {
      return element.classList.contains("mbt-header")
    }),
      subNav = Array.prototype.filter.call(hidingNav, function (element) {
        return element.classList.contains('js-hide-nav--sub');
      });

    var scrolling = false,
      previousTop = window.scrollY,
      currentTop = window.scrollY,
      scrollDelta = 10,
      scrollOffset = 150, // scrollY needs to be bigger than scrollOffset to hide navigation
      headerHeight = 0;
    var navIsFixed = false; // check if main navigation is fixed
    if (mainNav.length > 0 && mainNav[0].classList.contains('hide-nav--fixed')) navIsFixed = true;

    // store button that triggers navigation on mobile
    var triggerMobile = getTriggerMobileMenu();
    var prevElement = createPrevElement();
    var mainNavTop = 0;
    // list of classes the hide-nav has when it is expanded -> do not hide if it has those classes
    var navOpenClasses = hidingNav[0].getAttribute('data-nav-target-class'),
      navOpenArrayClasses = [];
    if (navOpenClasses) navOpenArrayClasses = navOpenClasses.split(' ');
    getMainNavTop();
    if (mainNavTop > 0) {
      scrollOffset = scrollOffset + mainNavTop;
    }

    // init navigation and listen to window scroll event
    getHeaderHeight();
    initSecondaryNav();
    initFixedNav();
    resetHideNav();
    window.addEventListener('scroll', function (event) {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(resetHideNav);
    });

    window.addEventListener('resize', function (event) {
      if (scrolling) return;
      scrolling = true;
      window.requestAnimationFrame(function () {
        if (headerHeight > 0) {
          getMainNavTop();
          getHeaderHeight();
          initSecondaryNav();
          initFixedNav();
        }
        // reset both navigation
        hideNavScrollUp();

        scrolling = false;
      });
    });

    function getHeaderHeight() {
      headerHeight = mainNav[0].offsetHeight;
    };

    function initSecondaryNav() { // if there's a secondary nav, set its top equal to the header height
      if (subNav.length < 1 || mainNav.length < 1) return;
      subNav[0].style.top = (headerHeight - 1) + 'px';
    };

    function initFixedNav() {
      if (!navIsFixed || mainNav.length < 1) return;
      mainNav[0].style.marginBottom = '-' + headerHeight + 'px';
    };

    function resetHideNav() { // check if navs need to be hidden/revealed
      currentTop = window.scrollY;
      if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
        hideNavScrollDown();
      } else if (previousTop - currentTop > scrollDelta || (previousTop - currentTop > 0 && currentTop < scrollOffset)) {
        hideNavScrollUp();
      } else if (previousTop - currentTop > 0 && subNav.length > 0 && subNav[0].getBoundingClientRect().top > 0) {
        setTranslate(subNav[0], '0%');
      }
      // if primary nav is fixed -> toggle bg class
      if (navIsFixed) {
        var scrollTop = window.scrollY || window.pageYOffset;
        if (scrollTop > headerHeight + mainNavTop) Util.addClass(el, className);
        else Util.removeClass(el, className);
      }
      previousTop = currentTop;
      scrolling = false;
    };

    function hideNavScrollDown() {
      // if there's a secondary nav -> it has to reach the top before hiding nav
      if (subNav.length > 0 && subNav[0].getBoundingClientRect().top > headerHeight) return;
      // on mobile -> hide navigation only if dropdown is not open
      if (triggerMobile && triggerMobile.getAttribute('aria-expanded') == "true") return;
      // check if main nav has one of the following classes
      if (mainNav.length > 0 && (!navOpenClasses || !checkNavExpanded())) {
        setTranslate(mainNav[0], '-100%');
        mainNav[0].addEventListener('transitionend', addOffCanvasClass);
      }
      if (subNav.length > 0) setTranslate(subNav[0], '-' + headerHeight + 'px');
    };

    function hideNavScrollUp() {
      if (mainNav.length > 0) { setTranslate(mainNav[0], '0%'); Util.removeClass(mainNav[0], 'hide-nav--off-canvas'); mainNav[0].removeEventListener('transitionend', addOffCanvasClass); }
      if (subNav.length > 0) setTranslate(subNav[0], '0%');
    };

    function addOffCanvasClass() {
      mainNav[0].removeEventListener('transitionend', addOffCanvasClass);
      Util.addClass(mainNav[0], 'hide-nav--off-canvas');
    };

    function setTranslate(element, val) {
      element.style.transform = 'translateY(' + val + ')';
    };

    function getTriggerMobileMenu() {
      // store trigger that toggle mobile navigation dropdown
      var triggerMobileClass = hidingNav[0].getAttribute('data-mobile-trigger');
      if (!triggerMobileClass) return false;
      if (triggerMobileClass.indexOf('#') == 0) { // get trigger by ID
        var trigger = document.getElementById(triggerMobileClass.replace('#', ''));
        if (trigger) return trigger;
      } else { // get trigger by class name
        var trigger = hidingNav[0].getElementsByClassName(triggerMobileClass);
        if (trigger.length > 0) return trigger[0];
      }

      return false;
    };

    function createPrevElement() {
      // create element to be inserted right before the mainNav to get its top value
      if (mainNav.length < 1) return false;
      var newElement = document.createElement("div");
      newElement.setAttribute('aria-hidden', 'true');
      // mainNav[0].parentElement.insertAdjacentElement('beforebegin', newElement, mainNav[0]);
      // mainNav[0].parentElement.insertBefore(newElement, mainNav[0]);
      var prevElement = mainNav[0].previousElementSibling;
      prevElement.style.opacity = '0';
      return prevElement;
    };

    function getMainNavTop() {
      if (!prevElement) return;
      mainNavTop = prevElement.getBoundingClientRect().top + window.scrollY;
    };

    function checkNavExpanded() {
      var navIsOpen = false;
      for (var i = 0; i < navOpenArrayClasses.length; i++) {
        if (Util.hasClass(mainNav[0], navOpenArrayClasses[i].trim())) {
          navIsOpen = true;
          break;
        }
      }
      return navIsOpen;
    };

  } else {
    // if window requestAnimationFrame is not supported -> add bg class to fixed header
    var mainNav = document.getElementsByClassName('mbt-header');
    if (mainNav.length < 1) return;
    if (Util.hasClass(mainNav[0], 'hide-nav--fixed')) Util.addClass(mainNav[0], 'hide-nav--has-bg');
    // }
  }
}



elmApp.ports.isSubscribed.subscribe((isSubscribe) => {
  localStorage.setItem("isSubscribed", isSubscribe);
  localStorage.setItem("lastestSubscriptionDemanded", new Date().toISOString());
});

//********************************************************** */
/******          AUTHENTIFICATION            ************** */
/********************************************************** */

// Auth0 authorize subscription
elmApp.ports.auth0authorize.subscribe((url) => {
  localStorage.setItem("callbackUrl", window.location.href);
  webAuth.loginWithRedirect();
});
// Log out of Auth0 subscription
elmApp.ports.auth0logout.subscribe(async function (path) {
  localStorage.removeItem("user");
  webAuth.logout({
    returnTo: `${window.location.origin}/${path || ""}`
  });
});

elmApp.ports.handleRedirectCallback.subscribe(async () => {
  await webAuth.handleRedirectCallback();
  const resp = await getUserData();
  elmApp.ports.auth0authResult.send(resp);
});

elmApp.ports.onSearchbarDropdownForceClose.subscribe((id) => {
  window.requestAnimationFrame(() => {
    $(".mbt-search-bar__label").removeClass("js-z-index");
    $(".mbt-search-bar__dropdown").removeClass("js-open");
    $("body, html").removeClass("active-menu");
    $(document).off("click");
  });
});

elmApp.ports.onSearchbarDropdownClick.subscribe((id) => {
  const selector = $(`#${id}`);

  window.requestAnimationFrame(() => {
    if (!$(selector).next(".mbt-search-bar__dropdown").hasClass("js-open")) {
      $(".mbt-search-bar__dropdown").removeClass("js-open");
      $(".mbt-search-bar__label").removeClass("js-z-index");
      $("body, html").removeClass("active-menu");
    }

    $(selector).next(".mbt-search-bar__dropdown").toggleClass("js-open");

    if ($(selector).hasClass("mbt-search-bar__label--field")) {
      $(selector).toggleClass("js-z-index");
    }
    if (window.matchMedia("(max-width: 768px)").matches) {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
    }
    $("body, html").addClass("active-menu");

    // When Click outside
    $(document).on("click", function (event) {
      if (!selector.parent().has(event.target).length) {
        $(".mbt-search-bar__dropdown").removeClass("js-open");
        $(".mbt-search-bar__label").removeClass("js-z-index");
        $("body, html").removeClass("active-menu");
        $(document).off("click");
      }
    });

    // When click close
    $(".mbt-search-bar__dropdown-close").on("click", function () {
      $(".mbt-search-bar__dropdown").removeClass("js-open");
      $(selector).removeClass("js-z-index");
      // $(".mbt-search-bar__label").removeClass("js-z-index");
      $("body, html").removeClass("active-menu");
      $(".mbt-search-bar__dropdown-close").off("click");
    });
  });
});

elmApp.ports.onFormDropdownForceClose.subscribe((id) => {
  window.requestAnimationFrame(() => {
    $(".mbt-modified-form__label").removeClass("js-z-index");
    $(".mbt-modified-form__dropdown").removeClass("js-open");
    $("body, html").removeClass("active-menu");
    $(document).off("click");
  });
});

elmApp.ports.onFormDropdownClick.subscribe((id) => {
  const selector = $(`#${id}`);

  window.requestAnimationFrame(() => {
    if (!$(selector).next(".mbt-modified-form__dropdown").hasClass("js-open")) {
      $(".mbt-modified-form__dropdown").removeClass("js-open");
      $(".mbt-modified-form__label").removeClass("js-z-index");
      $("body, html").removeClass("active-menu");
    }

    $(selector).next(".mbt-modified-form__dropdown").toggleClass("js-open");

    if ($(selector).hasClass("mbt-modified-form__label--field")) {
      $(selector).toggleClass("js-z-index");
    }
    if (window.matchMedia("(max-width: 768px)").matches) {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
    }
    $("body, html").addClass("active-menu");

    // When Click outside
    $(document).on("click", function (event) {
      if (!selector.parent().has(event.target).length) {
        $(".mbt-modified-form__dropdown").removeClass("js-open");
        $(".mbt-modified-form__label").removeClass("js-z-index");
        $("body, html").removeClass("active-menu");
        $(document).off("click");
      }
    });

    // When click close
    $(".mbt-modified-form__dropdown-close").on("click", function () {
      $(".mbt-modified-form__dropdown").removeClass("js-open");
      $(selector).removeClass("js-z-index");
      // $(".mbt-modified-form__label").removeClass("js-z-index");
      $("body, html").removeClass("active-menu");
      $(".mbt-modified-form__dropdown-close").off("click");
    });
  });
});

// elmApp.ports.onDropdownForceClose.subscribe(() => {
//   window.requestAnimationFrame(() => {
//     $(".mbt-dropdown__click.js-active").removeClass("js-active");
//   });
// });

elmApp.ports.onDropdownClick.subscribe((id) => {
  const selector = $(`#${id}`);
  window.requestAnimationFrame(() => {
    $(selector).children(".mbt-dropdown__click").toggleClass("js-active");
    if (id === "my-package-dropdown-id") {
      $(selector).children(".mbt-checkout").toggleClass("js-active");
    }
  });

  $(document).on("click", function (e) {
    if (!$(selector).has(e.target).length) {
      $(".dropdown__click.js-active, .mbt-dropdown__click.js-active, .mbt-checkout.js-active").removeClass("js-active");
    }


    $(document).off("click");
  });


});

$(".burger__toggle, .mbt-burger__toggle").on("change", (function () {
  window.requestAnimationFrame(() => {
    if ($(this).is(":checked")) {
      $("body, html").addClass("active-menu");
      $(".header, .mbt-header").addClass("js-active");
    } else {
      $("body, html").removeClass("active-menu");
      $(".header, .mbt-header").removeClass("js-active");
    }
  });
}));


elmApp.ports.onForceCloseMobileMenu.subscribe(() => {
  window.requestAnimationFrame(() => {
    $(".burger__toggle, .mbt-burger__toggle").prop("checked", false);
    $("body, html").removeClass("active-menu");
    $(".header, .mbt-header").removeClass("js-active");
  });
});

elmApp.ports.onStepLoaded.subscribe((id) => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    const step = document.getElementById(id);
    if (step) step.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
  }
});

elmApp.ports.toggleFormModal.subscribe((forceOpen) => {
  if ($('.mbt-search-bar--modal').hasClass("js-open") && !forceOpen) {
    $('.mbt-search-bar--modal').removeClass("js-open");
    $("body, html").removeClass("active-menu");
    return;
  }
  $('.mbt-search-bar--modal').addClass("js-open");
  $("body, html").addClass("active-menu");
})


elmApp.ports.toggleFilter.subscribe((forceOpen) => {
  if ($('.sidebar').hasClass("js-active") && !forceOpen) {
    $('.sidebar').removeClass("js-active");
    return;
  }
  $('.sidebar').addClass("js-active");
})






//********************************************************** */
/******          GEOLOCALISATION            ************** */
/********************************************************** */

getLocation();

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
  }
}

function showPosition(position) {
  elmApp.ports.handleGeoLocation.send([position.coords.latitude, position.coords.longitude]);
}

/********************************************************** */
/******          Click outside           ************** */
/********************************************************** */

class ClickOutside extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.onMouseDown = (e) => {
      const isOutside = !this.contains(e.target);

      if (isOutside) {
        var event = new CustomEvent("clickoutside");
        this.dispatchEvent(event);
      }
    };

    window.addEventListener("mousedown", this.onMouseDown);
  }

  disconnectedCallback() {
    window.removeEventListener("mousedown", this.onMouseDown);
  }
}

customElements.define("on-click-outside", ClickOutside);


(function () {
  var script_tag = document.createElement('script');
  script_tag.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GTAG_ID}`;
  document.head.appendChild(script_tag);
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', process.env.GTAG_ID);
  gtag('set', { branch: process.env.HEAD })
})();